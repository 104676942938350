import React, { useState, useEffect } from "react";
import { defer } from "rxjs";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { NavBar } from "../../components/NavBar";
import AdminModal from "./AdminModal";
import { getAdministradoresList } from "../../services/usuarios.service";
import HeaderActions from "../../components/Header-actions";
import { CheckSquare, XSquare } from "phosphor-react";
import Tooltip from "@mui/material/Tooltip";

const Administradores = () => {
  const [itemsList, setItemsList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState({});
  const handleModal = (cell) => {
    setItemSelected(cell.row);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const subscription = defer(() => getAdministradoresList()).subscribe(
      (list) => {
        setItemsList(list);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const localRefresh = async (updatedItem) => {
    setLoading(true);
    const idxToRefresh = itemsList.findIndex((c) => c.id === updatedItem.id);
    let updatedList = [];
    if (idxToRefresh > -1) {
      updatedList = [
        ...itemsList.slice(0, idxToRefresh),
        updatedItem,
        ...itemsList.slice(idxToRefresh + 1),
      ];
    } else {
      updatedList = [...itemsList, updatedItem];
    }
    setItemsList(updatedList);
    setLoading(false);
  };

  const fullRefresh = () => {
    setLoading(true);
    getAdministradoresList().then((list) => {
      setItemsList(list);
      setLoading(false);
    });
  };

  const columns = [
    {
      field: "profileURL",
      headerName: "Foto",
      width: 60,
      renderCell: (params) => {
        return <Avatar alt="Foto de perfil" src={params.row.profileURL} />;
      },
    },
    {
      field: "nombre",
      headerName: "Nombre",
      width: 280,
      renderCell: (params) => {
        return (
          <Tooltip title={`${params.row.nombre} ${params.row.apellidos}`}>
            <strong>{`${params.row.nombre} ${params.row.apellidos}`}</strong>
          </Tooltip>
        );
      },
    },
    {
      field: "correo",
      headerName: "Correo",
      width: 220,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.correo}>
            <span>{params.row.correo}</span>
          </Tooltip>
        );
      },
    },

    {
      field: "esAdmin",
      headerName: "Admin",
      width: 60,
      align: "center",
      renderCell: (params) => {
        return params.row.esAdmin ? (
          <CheckSquare size={24} color="#0e9524" />
        ) : (
          <XSquare size={24} color="#ea1f1f" />
        );
      },
    },
    {
      field: "admin_todos_centros",
      headerName: "Todos los centros",
      width: 140,
      align: "center",
      renderCell: (params) => {
        return params.row.admin_todos_centros ? (
          <CheckSquare size={24} color="#0e9524" />
        ) : (
          <XSquare size={24} color="#ea1f1f" />
        );
      },
    },
    {
      field: "acceso_enviosWinda",
      headerName: "Envios Winda",
      width: 110,
      align: "center",
      renderCell: (params) => {
        return params.row.acceso_enviosWinda ? (
          <CheckSquare size={24} color="#0e9524" />
        ) : (
          <XSquare size={24} color="#ea1f1f" />
        );
      },
    },
  ];

  return (
    <Box sx={{ display: "flex", mt: 4, mb: 4 }}>
      <NavBar selected="Administradores" />
      <Container maxWidth="lg">
        <Header title="Administradores" subtitle="Listado de Administradores" />
        <HeaderActions refresh={fullRefresh} />
        <AdminModal
          item={itemSelected}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          refresh={localRefresh}
        />
        <DataGrid
          rows={itemsList}
          columns={columns}
          sx={{ width: "100%", height: 600 }}
          loading={isLoading}
          autoHeight
          pageSize={15}
          rowsPerPageOptions={[15]}
          onCellClick={handleModal}
        />
      </Container>
    </Box>
  );
};

export default Administradores;
