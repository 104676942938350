import { firestore } from "../firebase";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  addDoc,
  query,
  orderBy,
} from "firebase/firestore";

const CLIENTES_COL = "clientes";

export const getClientesList = async () => {
  const clientes = await getClientes();
  return clientes.map((c) => {
    return { ...c.data(), id: c.id };
  });
};

const getClientes = async () => {
  try {
    const colRef = collection(firestore, CLIENTES_COL);
    const q = query(colRef, orderBy("nombreCliente"));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.docs || querySnapshot.size === 0) {
      return [];
    }
    return querySnapshot.docs;
  } catch (err) {
    console.error("Error obteniendo cliente", err);
    return [];
  }
};

export const saveCliente = async (data) => {
  const cursoDoc = doc(firestore, CLIENTES_COL, data.id);
  try {
    await updateDoc(cursoDoc, data);
    return { success: true, data: data };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false, error: err };
  }
};

export const createCliente = async (data) => {
  try {
    const res = await addDoc(collection(firestore, CLIENTES_COL), data);

    return { success: true, data: { ...data, id: res.id } };
  } catch (err) {
    console.log("Error:", err);
    return { success: false, error: err };
  }
};
