import { firestore } from "../firebase";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  addDoc,
  where,
  query,
} from "firebase/firestore";

const VESRIONES_COL = "cursos.versiones";

export const getVersionesCursos = async () => {
  const colRef = await getDocs(collection(firestore, "cursos.versiones"));

  return colRef.empty
    ? []
    : colRef.docs.map((c) => {
        return { ...c.data(), id: c.id };
      });
};

export const createVersion = async (data) => {
  if (await getVersionByCodigoAndVersion(data.codigo, data.version)) {
    return {
      success: false,
      error: `Ya existe una version con numero ${data.version}`,
    };
  }

  try {
    const contenidos = parseContenidos(data.contenidosTexto);
    data = { ...data, contenidos };

    const res = await addDoc(collection(firestore, VESRIONES_COL), data);

    return { success: true, data: { ...data, id: res.id } };
  } catch (err) {
    console.log("Error:", err);
    return { success: false, error: err };
  }
};

export const saveVersion = async (data) => {
  const itemDoc = doc(firestore, VESRIONES_COL, data.id);
  try {
    const contenidos = parseContenidos(data.contenidosTexto);
    data = { ...data, contenidos };
    await updateDoc(itemDoc, data);
    return { success: true, data: data };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false, error: err };
  }
};

const getVersionByCodigoAndVersion = async (codigo, version) => {
  try {
    const colRef = collection(firestore, VESRIONES_COL);
    const q = query(
      colRef,
      where("codigo", "==", codigo.toUpperCase()),
      where("version", "==", version.toUpperCase())
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.docs || querySnapshot.size === 0) {
      return null;
    }
    return querySnapshot.docs[0].data();
  } catch (err) {
    console.error("Error obteniendo version", err);
    return null;
  }
};

const parseContenidos = (contenidos) => {
  const result = {};
  Object.entries(contenidos).forEach(([lang, contenido]) => {
    if (contenido && contenido !== '""') {
      const contenidoAsArray = convertToArray(JSON.parse(contenido));
      result[lang] = JSON.stringify(contenidoAsArray);
    }
  });
  return result;
};

const convertToArray = (data) => {
  const content = data.toString().split("\n");
  const finalContent = [];
  let currDepth = 1;
  let niveles = [[], [], [], [], []];
  content
    .filter((l) => l.trim())
    .forEach((line) => {
      line = line.trim();
      const numeral = line.split(" ")[0];
      const depth = numeral.split(".").filter((e) => e).length;

      const newLine = line.split(" ").slice(1).join(" ");

      if (depth >= currDepth) {
        niveles[depth].push(newLine);
      } else {
        // vuelvo a nivel padre
        while (depth < currDepth) {
          niveles[currDepth - 1].push(niveles[currDepth]);
          niveles[currDepth] = [];
          currDepth--;
        }
        niveles[depth].push(newLine);
      }
      currDepth = depth;
    });
  if (currDepth > 1) {
    while (1 < currDepth) {
      niveles[currDepth - 1].push(niveles[currDepth]);
      niveles[currDepth] = [];
      currDepth--;
    }
  }
  finalContent.push(niveles[1]);
  return finalContent;
};
