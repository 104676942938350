/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import Login from "./pages/Login";

// @mui icons
import Icon from "@mui/material/Icon";
import Dashboard from "./pages/Dashboard";
import Cursos from "./pages/cursos";
import Centros from "./pages/centros";
import VersionesCursos from "./pages/contenido-cursos";
import Administradores from "./pages/administradores";
import Configuracion from "./pages/config-general";
import Clientes from "./pages/clientes";
import Docentes from "./pages/docentes";
import { ActividadDocentes } from "./pages/actividadDocentes";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/",
    protected: true,
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/login",
    protected: false,
    component: <Login />,
  },
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/home",
    protected: true,
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Cursos",
    key: "cursos",
    icon: <Icon fontSize="small">course</Icon>,
    protected: true,
    route: "/cursos",
    component: <Cursos />,
  },
  {
    type: "collapse",
    name: "Centros",
    key: "centros",
    protected: true,
    route: "/centros",
    component: <Centros />,
  },
  {
    type: "collapse",
    name: "Versiones",
    key: "versiones",
    protected: true,
    route: "/versiones-cursos",
    component: <VersionesCursos />,
  },
  {
    type: "collapse",
    name: "Administradores",
    key: "administradores",
    protected: true,
    route: "/administradores",
    component: <Administradores />,
  },
  {
    type: "collapse",
    name: "Docentes",
    key: "docentes",
    protected: true,
    route: "/docentes",
    component: <Docentes />,
  },
  {
    type: "collapse",
    name: "Actividad docentes",
    key: "actividad-docentes",
    protected: true,
    route: "/actividad-docentes",
    component: <ActividadDocentes />,
  },
  {
    type: "collapse",
    name: "Clientes",
    key: "clientes",
    protected: true,
    route: "/clientes",
    component: <Clientes />,
  },
  {
    type: "collapse",
    name: "Configuracion",
    key: "configuracion",
    protected: true,
    route: "/config-general",
    component: <Configuracion />,
  },
];

export default routes;
