import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Header from "../../components/Header";
import { NavBar } from "../../components/NavBar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { getActividadDocentesList } from "../../services/usuarios.service";
import { defer } from "rxjs";
import {
  startOfMonth,
  addMonths,
  startOfToday,
  endOfMonth,
  isValid,
  format,
  parseISO,
} from "date-fns";
import { Button } from "@mui/material";

const InfoActividad = ({ actividad }) => {
  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Impartidos:
      </Typography>
      <Box
        sx={{
          padding: 1,
          border: "1px solid rgba(224, 224, 224, 1)",
          borderRadius: 1,
        }}
      >
        <ul>
          {actividad?.map((act) => (
            <li key={`${act.modulo}-${act.edicion}`}>
              <span>
                <strong>{act.edicion}</strong>{" "}
                {`(${act.modulo}) ${act.fechaFin}`}
              </span>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

const Row = ({ row }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* <TableCell component="th" scope="row">
          <Avatar alt="Foto de perfil" src={row.profileURL} />
        </TableCell> */}
        <TableCell>
          <Tooltip title={row.nombre}>
            <strong>{row.nombre}</strong>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={row.email}>
            <span>{row.email}</span>
          </Tooltip>
        </TableCell>
        <TableCell align="center">{row.impartidos?.length ?? 0}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <InfoActividad actividad={row.impartidos} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const DocentesTable = ({ rows }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {/* <TableCell>Foto</TableCell> */}
            <TableCell>Nombre</TableCell>
            <TableCell>Correo</TableCell>
            <TableCell align="center">Cantidad</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((edicion) => (
            <Row key={edicion.nombre} row={edicion} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Filtro = ({ isLoading, desde, hasta, setDesde, setHasta }) => {
  const [desdeLocal, setDesdeLocal] = useState(desde);
  const [hastaLocal, setHastaLocal] = useState(hasta);
  const [inputError, setInputError] = useState({});

  const validate = () => {
    let tempError = inputError;
    if (!isValid(parseISO(desdeLocal))) {
      tempError = { ...tempError, desde: "Seleccione una fecha valida" };
    }
    if (!isValid(parseISO(hastaLocal))) {
      tempError = { ...tempError, hasta: "Seleccione una fecha valida" };
    }
    setInputError(tempError);
    return Object.values(tempError).every((e) => !e);
  };

  return (
    <Paper
      sx={{
        padding: "8px;",
        margin: "8px 0px 8px 0px;",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={5} sm={5}>
          <TextField
            id="desde"
            label="Desde"
            name="desde"
            fullWidth
            size="small"
            value={desdeLocal}
            onChange={(e) => setDesdeLocal(e.target.value)}
            type="date"
            disabled={isLoading}
            error={!!inputError["desde"]}
            helperText={inputError["desde"]}
            required
          />
        </Grid>
        <Grid item xs={5} sm={5}>
          <TextField
            id="hasta"
            label="Hasta"
            name="hasta"
            fullWidth
            size="small"
            value={hastaLocal}
            onChange={(e) => setHastaLocal(e.target.value)}
            type="date"
            disabled={isLoading}
            error={!!inputError["desde"]}
            helperText={inputError["desde"]}
            required
          />
        </Grid>
        <Grid item xs={2} sm={2}>
          <Button
            variant="contained"
            onClick={() => {
              if (validate()) {
                setDesde(desdeLocal);
                setHasta(hastaLocal);
              }
            }}
          >
            Filtrar
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const aMonthAgo = addMonths(startOfToday(), -1);
export const ActividadDocentes = () => {
  const [isLoading, setLoading] = useState(true);
  const [desde, setDesde] = useState(
    format(startOfMonth(aMonthAgo), "yyyy-MM-dd")
  );
  const [hasta, setHasta] = useState(
    format(endOfMonth(aMonthAgo), "yyyy-MM-dd")
  );
  const [itemsList, setItemsList] = useState([]);

  useEffect(() => {
    setLoading(true);
    const subscription = defer(() =>
      getActividadDocentesList(parseISO(desde), parseISO(hasta))
    ).subscribe((list) => {
      setItemsList(list);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [desde, hasta]);

  return (
    <Box sx={{ display: "flex", mt: 4, mb: 4 }}>
      <NavBar selected="Actividad Docentes" />
      <Container maxWidth="lg">
        <Header
          title="Activida Docentes"
          subtitle="Listado de docentes con actividad"
        />
        <Filtro
          isLoading={isLoading}
          desde={desde}
          hasta={hasta}
          setDesde={setDesde}
          setHasta={setHasta}
        />
        <DocentesTable rows={itemsList} />
      </Container>
    </Box>
  );
};
