import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { NavBar } from "../components/NavBar";
import { defer } from "rxjs";
import { getEdicionesActivas } from "../services/ediciones.service";
import { EdicionesTable } from "../components/EdicionesRow";

const Dashboard = () => {
  const [itemsList, setItemsList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const subscription = defer(() => getEdicionesActivas()).subscribe(
      (list) => {
        setItemsList(list);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <Box sx={{ display: "flex", mt: 4, mb: 4 }}>
      <NavBar selected="Dashboard" />
      <Container maxWidth="lg">
        <Header
          title={"Proximas ediciones"}
          subtitle={"Listado de ediciones activas o proximas a comenzar"}
        />
        <EdicionesTable rows={itemsList} />
      </Container>
    </Box>
  );
};

export default Dashboard;
