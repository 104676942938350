import { firestore, storage } from "../firebase";
import {
  collection,
  getDocs,
  doc,
  query,
  where,
  getDoc,
  updateDoc,
  limit,
} from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { getStorageURL } from "./config.service";
import AvatarImage from "../assets/images/AvatarImage.svg";

const USUARIOS_COL = "usuarios";

export const getUsuario = async (usuarioId) => {
  const docRef = doc(firestore, USUARIOS_COL, usuarioId);
  return getDoc(docRef)
    .then((doc) => {
      return doc.exists() ? doc.data() : null;
    })
    .catch((err) => {
      console.error("Error obteniendo usuario: ", err);
      return { error: true };
    });
};

export const getFileURL = async (filePath) => {
  try {
    const url = await getDownloadURL(ref(storage, filePath));
    return url;
  } catch (err) {
    console.error(`Error obteniendo URL para ${filePath}`);
    return AvatarImage;
  }
};

/** Administradores */
export const getAdministradoresList = async () => {
  const colRef = collection(firestore, USUARIOS_COL);
  const q = query(colRef, where("esAdmin", "==", true));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.docs || querySnapshot.size === 0) {
    return [];
  }

  return Promise.all(
    querySnapshot.docs.map(async (c) => {
      const data = c.data();
      return {
        nombre: data.nombre,
        apellidos: data.apellidos,
        correo: data.correo,
        esAdmin: !!data.esAdmin,
        admin_todos_centros: data.admin_todos_centros,
        acceso_enviosWinda: data.acceso_enviosWinda,
        id: c.id,
        profileURL: await getURLProfilePicture(data.id_usuario),
      };
    })
  );
};

export const saveAdmin = async (data) => {
  const itemDoc = doc(firestore, USUARIOS_COL, data.id);
  try {
    const permisos = {
      esAdmin: data.esAdmin,
      admin_todos_centros: data.admin_todos_centros || false,
      acceso_enviosWinda: data.acceso_enviosWinda || false,
    };
    await updateDoc(itemDoc, permisos);
    return { success: true, data: data };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false, error: err };
  }
};

/** Docentes */
export const getDocentesList = async () => {
  const colRef = collection(firestore, USUARIOS_COL);
  const q = query(colRef, where("esDocente", "==", true));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.docs || querySnapshot.size === 0) {
    return [];
  }

  return Promise.all(
    querySnapshot.docs.map(async (c) => {
      const data = c.data();
      return {
        nombre: data.nombre,
        apellidos: data.apellidos,
        correo: data.correo,
        esDocente: data.esDocente,
        recursos_disponibles: data.recursos_disponibles || [],
        recursos: data.recursos, // 1,2,3 y "3"
        lugar: data.lugar,
        centros: data._centro_solicitado,
        edicionesAceptadasDocente: data.edicionesAceptadasDocente || [],
        profileURL: await getURLProfilePicture(data.id_usuario),
        id: c.id,
      };
    })
  );
};

const getURLProfilePicture = async (idUsuario) => {
  const picPath = "avatares/thumbs/" + idUsuario + "_800x600.jpg";
  return getStorageURL(picPath)
    .then((url) => url)
    .catch((err) => {
      console.log("Error getURLProfilePicture ", err);
      return "empty";
    });
};

export const saveDocente = async (data) => {
  const itemDoc = doc(firestore, USUARIOS_COL, data.id);
  try {
    const permisos = {
      esDocente: data.esDocente || false,
    };
    await updateDoc(itemDoc, permisos);
    return { success: true, data: data };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false, error: err };
  }
};

export const getActividadDocentesList = async (desde, hasta) => {
  const colRef = collection(firestore, "registroCalendario");
  const q = query(
    colRef,
    where("edicionFinalizada", "==", true),
    where("inicio_timestamp", ">=", desde.getTime() / 1000),
    limit(100)
  );
  const ediciones = await getDocs(q);

  let docentesInfo = new Map();
  for (const edicionDoc of ediciones.docs) {
    const data = edicionDoc.data();

    if (data.fin_timestamp > hasta.getTime() / 1000) {
      continue;
    }

    const edicion = data.nombre;
    const lugar = data.lugar;
    const modulos = data.modulos;
    modulos.forEach((modulo) => {
      const infoModulo = data[modulo];
      if (infoModulo) {
        const emails = infoModulo[6].split(";");
        const nombres = infoModulo[2].split(";");
        const horas = infoModulo[15];
        const inicio = infoModulo[0];
        const fin = infoModulo[1];

        emails.forEach((email, idx) => {
          const impartido = {
            fechaInicio: inicio,
            fechaFin: fin,
            horas,
            modulo,
            edicion,
            lugar,
          };
          const docenteInfo = docentesInfo.get(email) || {
            email,
            nombre: nombres[idx],
            impartidos: [],
          };
          docenteInfo.impartidos.push(impartido);

          docentesInfo.set(email, docenteInfo);
        });
      }
    });
  }

  return Array.from(docentesInfo.values());
};

/** Alumnos edicion */
export const getAlumnosByEmail = async (emails, edicion) => {
  if (!emails || emails.length < 1) return [];
  const colRef = collection(firestore, USUARIOS_COL);
  const q = query(colRef, where("correo", "in", emails));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.docs || querySnapshot.size === 0) {
    return [];
  }

  return Promise.all(
    querySnapshot.docs.map(async (c) => {
      const data = c.data();
      const modulosInscripto1 = data.edicionesAceptadasAlumno
        ?.filter((m) => m.includes(edicion))
        .map((m) => m.split("//")[1]);
      const modulosInscripto = modulosInscripto1
        .flatMap((m) => m?.split(";"))
        .filter((m) => !!m);
      const aprobadosEdicion = data.modulosAprobados
        ?.filter((m) => m.includes(edicion))
        .map((m) => ({
          modulo: m.split(";")[0],
          aprobado: true,
          fecha: m.split(";")[4],
        }));

      const cursando = modulosInscripto.map((m) => {
        const aprobado = aprobadosEdicion?.find((a) => a.modulo === m);
        return aprobado
          ? { ...aprobado, modulo: m.toUpperCase() }
          : { modulo: m.toUpperCase(), aprobado: false };
      });
      return {
        nombre: data.nombre,
        apellidos: data.apellidos,
        correo: data.correo,
        profileURL: await getURLProfilePicture(data.id_usuario),
        id: c.id,
        preregistrado: data.preregistrado && data.registroStep !== 99,
        windaId: data.winda,
        irataId: data.idirata,
        modulosInscripto: modulosInscripto,
        modulosAprobados: aprobadosEdicion,
        cursando: cursando,
      };
    })
  );
};
