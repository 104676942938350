import intl from "react-intl-universal";
const en = require("../lang/en.json");
const es = require("../lang/es.json");
const fr = require("../lang/fr.json");


//localStorage Key
const STORAGE_KEY = "lang";
const STORAGE_LEGACY_KEY = "idioma";

export const SUPPORTED_LOCALES = [
  {
    name: "English",
    value: "en",
    flag: "GB"
  },
  {
    name: "Español",
    value: "es",
    flag: "ES"
  },
  {
    name: "Française",
    value: "fr",
    flag: "FR"
  },
];

// app locale data
const _locales = {
  en: en,
  es: es,
  fr: fr,
};

const _localesLegacy = {
  en: "ingles",
  es: "español",
  fr: "frances",
  ar: "arabe"
};

export const loadLocale = (value) => {
  setLocalStorage(value);
  switch (value) {
    case "es":
      return intl.init({ currentLocale: "es", locales: _locales });  
    case "en":
      return intl.init({ currentLocale: "en", locales: _locales });
    case "fr":
      return intl.init({ currentLocale: "fr", locales: _locales });
    default:
      return intl.init({ currentLocale: "es", locales: _locales });
  }
};

export const isValidLocale = (locale) => {
  return !!_locales[locale];
}

export const getCurrentLocale = () => {
  const _lang = localStorage.getItem(STORAGE_KEY);
  return isValidLocale(_lang) ? _lang : "es";
}

const setLocalStorage = (locale) => {
  localStorage.setItem(STORAGE_KEY, locale);
  localStorage.setItem(STORAGE_LEGACY_KEY, _localesLegacy[locale] || "español");
}


// Inicia Intl con el locale de localStorage (o "es" sino existe)
// Guarda el nuevo locale en localStorage.
const initLocale = getCurrentLocale();
setLocalStorage(initLocale);
intl.init({ currentLocale: initLocale, locales: _locales });
