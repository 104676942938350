import { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import InfoIcon from "@mui/icons-material/Info";
import { DataGrid } from "@mui/x-data-grid";
import { Alert, Avatar, Chip, Grid, Stack, Tooltip } from "@mui/material";
import { defer } from "rxjs";
import { getAlumnosByEmail } from "../services/usuarios.service";

const InfoInscriptos = ({ alumnos, edicion }) => {
  const [itemsList, setItemsList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const subscription = defer(() =>
      getAlumnosByEmail(alumnos, edicion)
    ).subscribe((list) => {
      setItemsList(list);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [alumnos, edicion]);

  const columns = [
    {
      field: "profileURL",
      headerName: "Foto",
      width: 60,
      renderCell: (params) => {
        return <Avatar alt="Foto de perfil" src={params.row.profileURL} />;
      },
    },
    {
      field: "nombre",
      headerName: "Nombre",
      width: 280,
      renderCell: (params) => {
        return params.row.preregistrado ? (
          <strong>PREREGISTRADO</strong>
        ) : (
          <Tooltip title={`${params.row.nombre} ${params.row.apellidos}`}>
            <strong>{`${params.row.nombre} ${params.row.apellidos}`}</strong>
          </Tooltip>
        );
      },
    },
    {
      field: "correo",
      headerName: "Correo",
      width: 220,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.correo}>
            <span>{params.row.correo}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "modulos",
      renderHeader: () => (
        <>
          {"Modulos  "}
          <Tooltip title="Se muestran todos los modulos a los que el alumno está inscripto. En verde cuando han sido aprobados.">
            <InfoIcon />
          </Tooltip>
        </>
      ),
      width: 340,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            {params.row.cursando?.map((m) => {
              return (
                <Tooltip title={m.aprobado ? m.fecha : ""}>
                  <Chip
                    key={m.modulo}
                    label={m.modulo}
                    color={m.aprobado ? "success" : "default"}
                  />
                </Tooltip>
              );
            })}
          </Stack>
        );
      },
    },
    {
      field: "windaId",
      headerName: "Winda ID",
      width: 120,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.windaId}>
            <a
              rel="noreferrer"
              target="_blank"
              href={
                "https://winda.globalwindsafety.org/organisation/search/?searchString=" +
                params.row.windaId
              }
            >
              {params.row.windaId}
            </a>
          </Tooltip>
        );
      },
    },
  ];
  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Inscriptos
      </Typography>

      <DataGrid
        rows={itemsList}
        columns={columns}
        sx={{ width: "100%", height: 600 }}
        loading={isLoading}
        autoHeight
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Box>
  );
};

const InfoEdicion = ({ edicion }) => {
  return (
    <Box sx={{ margin: 1 }}>
      {edicion.problemas ? (
        <Alert severity="warning">{edicion.problemas.join(". ")}</Alert>
      ) : (
        ""
      )}
      <Typography variant="h6" gutterBottom component="div">
        Detalles
      </Typography>
      <Box
        sx={{
          padding: 1,
          border: "1px solid rgba(224, 224, 224, 1)",
          borderRadius: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              <strong>Centro:</strong> {edicion.centro}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              <strong>Lugar:</strong> {edicion.lugar}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              <strong>Tipo Centro:</strong> {edicion.windaFacilityType}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              <strong>Modulos en la edición:</strong>{" "}
              {edicion.modulos?.join(", ")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              <strong>Creado por:</strong> {edicion.creador_evento}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const EstadoChip = ({ label }) => {
  let color = "default";
  switch (label) {
    case "Activo":
      color = "info";
      break;
    case "Finalizado":
      color = "success";
      break;
    default:
      color = "default";
  }
  return <Chip label={label} color={color} size="small" />;
};

const Row = ({ row }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <strong>{row.nombre}</strong>
        </TableCell>
        <TableCell>{row.fecha_inicio}</TableCell>
        <TableCell>{row.fecha_fin}</TableCell>
        <TableCell align="center">
          <EstadoChip label={row.estado} />
        </TableCell>
        <TableCell align="center">
          {row.problemas ? <WarningRoundedIcon color="error" /> : ""}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <InfoEdicion edicion={row} />
            <InfoInscriptos
              alumnos={row.correos_alumnos}
              edicion={row.nombre}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const EdicionesTable = ({ rows }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Edicion</TableCell>
            <TableCell>Inicio</TableCell>
            <TableCell>Fin</TableCell>
            <TableCell align="center">Estado</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((edicion) => (
            <Row key={edicion.nombre} row={edicion} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
