import React, { useState, useEffect } from "react";

import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Loader } from "../../components/controls/Loader";

import { saveAdmin } from "../../services/usuarios.service";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const AdminModal = ({ item, isOpen, setIsOpen, refresh }) => {
  const initialFormState = () => {
    return {
      profileURL: "",
      correo: "",
      nombre: "",
      apellidos: "",
      esAdmin: false,
      admin_todos_centros: false,
      acceso_enviosWinda: false,
    };
  };
  const [isCreation, setIsCreation] = useState(true);
  const [formData, setFormData] = useState(initialFormState());
  const [isLoading, setLoading] = useState(true);
  const [inputError, setInputError] = useState({});

  useEffect(() => {
    setLoading(true);
    if (item && Object.keys(item).length > 0) {
      setFormData(item);
      setIsCreation(false);
    } else {
      setFormData(initialFormState());
      setIsCreation(true);
    }
    setLoading(false);
  }, [isOpen, item]);

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const onCheckedChange = (event) => {
    const { name, checked } = event.target;
    setFormData({ ...formData, [name]: checked });
  };
  const handleClose = () => {
    setIsOpen(false);
    setInputError({});
  };
  const handleSave = async () => {
    setLoading(true);
    const res = await saveAdmin(formData);
    if (res.success) {
      refresh(res.data);
      handleClose();
    } else {
      setInputError({ error: `Ocurrio un error: ${res.error}` });
    }
    setLoading(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {formData.correo
          ? `Administrador: ${formData.correo}`
          : "Nuevo Administrador"}
      </DialogTitle>
      <DialogContent sx={{ pt: "16px !important" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Avatar
              alt="Foto de perfil"
              src={formData.profileURL}
              sx={{ width: 160, height: 160 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="nombre"
              name="nombre"
              label="Nombre"
              fullWidth
              value={formData.nombre}
              onChange={onInputChange}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="apellidos"
              label="Apellidos"
              name="apellidos"
              fullWidth
              value={formData.apellidos}
              onChange={onInputChange}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="correo"
              name="correo"
              label="Correo"
              fullWidth
              value={formData.correo}
              onChange={onInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ fontSize: "small" }}>
              Permisos:
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="admin-label">Administrador</InputLabel>
              <Select
                labelId="admin-label"
                id="admin-select"
                label="Administrador"
                name="esAdmin"
                value={formData.esAdmin}
                onChange={onInputChange}
                fullWidth
                disabled={isLoading}
              >
                <MenuItem value={true}>SI</MenuItem>
                <MenuItem value={false}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label="Todos los centros"
              control={
                <Checkbox
                  name="admin_todos_centros"
                  checked={formData.admin_todos_centros || false}
                  onChange={onCheckedChange}
                  disabled={!formData.esAdmin || isLoading}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="Envios Winda"
              control={
                <Checkbox
                  name="acceso_enviosWinda"
                  checked={formData.acceso_enviosWinda || false}
                  onChange={onCheckedChange}
                  disabled={!formData.esAdmin || isLoading}
                />
              }
            />
          </Grid>

          {!!inputError.error ? (
            <Grid item xs={12}>
              <span style={{ color: "#ff0000" }}>{inputError.error}</span>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {isLoading && <Loader />}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cerrar
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={isLoading}>
          {isCreation ? "Crear Usuario" : "Guardar cambios"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminModal;
