import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import { getUsuario } from "../services/usuarios.service";
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { Loader } from "../components/controls/Loader";
import intl from "react-intl-universal";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [currentUsuarioDB, setCurrentUsuarioDB] = useState();
  const [loading, setLoading] = useState(true);
  const [connectionError, setConnectionError] = useState(false);

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  async function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
      .then(async (userCred) => {
        if (userCred.user) {
          const userDB = await getUsuario(userCred.user.email);
          if (!!userDB.esAdmin) return { success: true, userDB };
          else
            return {
              success: false,
              errorMsg:
                "Para ingresar en esta aplicación usted debe ser Adminsitrador.",
            };
        }
      })
      .catch((err) => {
        let error = "";
        if (err.code === "auth/wrong-password") {
          error = intl.get("validations.auth-wrong-password");
        } else if (err.code === "auth/user-not-found") {
          error = intl.get("validations.auth-user-not-found");
        } else if (err.code === "auth/invalid-email") {
          error = intl.get("validations.auth-invalid-email");
        } else if (err.code === "auth/user-disabled") {
          error = intl.get("validations.auth-user-disabled");
        } else {
          error = intl.get("validations.login-error");
        }
        return { success: false, errorMsg: error };
      });
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmail(email) {
    return updateEmail(currentUser, email);
  }

  function updatePassword(password) {
    return updatePassword(currentUser, password);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        const userDB = await getUsuario(user.email);
        if (userDB && !userDB.error) {
          setCurrentUsuarioDB(userDB);
        } else {
          setConnectionError(true);
        }
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    currentUsuarioDB,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };

  return (
    <>
      {connectionError ? (
        "No es posible conectarse al servidor, compruebe su conexion a internet e intente nuevamente."
      ) : loading ? (
        <Loader />
      ) : (
        <AuthContext.Provider value={value}>
          {!loading && children}
        </AuthContext.Provider>
      )}
    </>
  );
}
