import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Loader } from "../../components/controls/Loader";

import { saveCentro, createCentro } from "../../services/centros.service";

const CentroModal = ({ centro, isOpen, setIsOpen, refresh }) => {
  const initialFormState = () => {
    return {
      codigo: "",
      nombre_completo: "",
      pais: "",
      logo_url: "",
      activo: true,
    };
  };
  const [isCreation, setIsCreation] = useState(true);
  const [formData, setFormData] = useState(initialFormState());
  const [isLoading, setLoading] = useState(true);
  const [inputError, setInputError] = useState({});

  useEffect(() => {
    setLoading(true);
    if (centro && Object.keys(centro).length > 0) {
      setFormData(centro);
      setIsCreation(false);
    } else {
      setFormData(initialFormState());
      setIsCreation(true);
    }
    setLoading(false);
  }, [isOpen, centro]);

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleClose = () => {
    setIsOpen(false);
    setInputError({});
  };
  const handleSave = async () => {
    setLoading(true);
    if (validateInput()) {
      const res = isCreation
        ? await createCentro(formData)
        : await saveCentro(formData);
      if (res.success) {
        refresh(res.data);
        handleClose();
      } else {
        setInputError({ error: `Ocurrio un error: ${res.error}` });
      }
    }
    setLoading(false);
  };
  const validateInput = () => {
    const tempError = {};
    tempError.codigo = formData.codigo ? "" : "El codigo es requerido";
    tempError.pais = formData.pais ? "" : "El pais es requerido";
    tempError.nombre_completo = formData.nombre_completo
      ? ""
      : "El nombre es requerido";

    setInputError(tempError);
    return Object.values(tempError).every((e) => !e);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {formData.codigo ? `Centro: ${formData.codigo}` : "Nuevo Centro"}
      </DialogTitle>
      <DialogContent sx={{ pt: "16px !important" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="codigo"
              name="codigo"
              label="Codigo"
              fullWidth
              value={formData.codigo}
              onChange={onInputChange}
              disabled={isLoading || !!formData.id}
              error={!!inputError["codigo"]}
              helperText={inputError["codigo"]}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="nombreCompleto"
              name="nombre_completo"
              label="Nombre Completo"
              fullWidth
              value={formData.nombre_completo}
              onChange={onInputChange}
              disabled={isLoading || !!formData.id}
              error={!!inputError["nombre_completo"]}
              helperText={inputError["nombre_completo"]}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="pais"
              label="Pais"
              name="pais"
              fullWidth
              value={formData.pais}
              onChange={onInputChange}
              disabled={isLoading}
              error={!!inputError["pais"]}
              helperText={inputError["pais"]}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="logoUrl"
              label="Logo"
              name="logo_url"
              fullWidth
              value={formData.logo_url}
              onChange={onInputChange}
              disabled={isLoading}
              error={!!inputError["logo_url"]}
              helperText={inputError["logo_url"]}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="activo-label">Activo?</InputLabel>
              <Select
                labelId="activo-label"
                id="activo-select"
                label="Activo"
                name="activo"
                value={formData.activo}
                onChange={onInputChange}
              >
                <MenuItem value={true}>SI</MenuItem>
                <MenuItem value={false}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {!!inputError.error ? (
            <Grid item xs={12}>
              <span style={{ color: "#ff0000" }}>{inputError.error}</span>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {isLoading && <Loader />}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cerrar
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={isLoading}>
          {isCreation ? "Crear centro" : "Guardar cambios"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CentroModal;
