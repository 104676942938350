import React from "react";
import { Container, Box, Paper } from "@mui/material";
import Alert from '@mui/material/Alert';
import { Loader } from "../components/controls/Loader";
import { styled } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";


const theme = createTheme();


const StyledChildrenContainer = styled(Box)(
		({ theme }) => `
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-grow:1;
		gap: 1rem;
	`,
  );

const StyledPaper = styled(Paper)(
	({ theme }) => `
		display: flex;	
		flex-direction: column;
		gap: 1rem;     
		padding: 1rem 1rem;   
		${theme.breakpoints.down("sm")} {
			min-height: 70vh;
		},
		${theme.breakpoints.up("md")} {
			min-height: 40vh;
		},
	`);

const StyledContainer = styled(Container)(
	({ theme }) => `
	padding-top: 5%;
	padding-bottom: 10%;
	${theme.breakpoints.down("sm")} {
		padding: 8px;
		padding-top: 5%;
		margin-bottom: 5%;
	},
`,
);



export const ChildrenContainer = (props) => {
	return (<StyledChildrenContainer onSubmit={props.onSubmit}>
		{props.children}
	</StyledChildrenContainer>)
};

export const AuthStyledContainer = (props) => {
	const { isLoading, error, children, successMessage } = props;
	// const classes = useStyles();

	return (
		<StyledContainer maxWidth={props.fullWidth ? null : "sm"} sx={{
			[theme.breakpoints.down("sm")]: {
				padding: "8px",
				paddingTop: "5%",
				marginBottom: "5%",
			},
			[theme.breakpoints.up("sm")]: {
				paddingTop: "5%",
				paddingBottom: "10%",
			}
		}} >
			<Box position="relative">
				<StyledPaper variant="outlined" >
					{error && <Alert severity="error">{error}</Alert>}
					{successMessage && (
						<Alert severity="success">{successMessage}</Alert>
					)}
					{isLoading && <Loader />}
					<Box >
						{children}
					</Box>
				</StyledPaper>
			</Box>
		</StyledContainer>
	);
};
