export const countryToFlag = (isoCode) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

/*
  type Country = {
    id: string,
    code: string,
    name: string,
  };
*/
export const getCountryList = (locale = "es") => {
	return allCountries[locale];
}

// lista generada con /tools/lang/getCountries.js
export const allCountries = {
    "es": [
        {
            "id": "AF",
            "name": "Afganistán",
            "code": "93"
        },
        {
            "id": "AX",
            "name": "Alandia",
            "code": "358"
        },
        {
            "id": "AL",
            "name": "Albania",
            "code": "355"
        },
        {
            "id": "DE",
            "name": "Alemania",
            "code": "49"
        },
        {
            "id": "AD",
            "name": "Andorra",
            "code": "376"
        },
        {
            "id": "AO",
            "name": "Angola",
            "code": "244"
        },
        {
            "id": "AI",
            "name": "Anguilla",
            "code": "1-264"
        },
        {
            "id": "AQ",
            "name": "Antártida",
            "code": "672"
        },
        {
            "id": "AG",
            "name": "Antigua y Barbuda",
            "code": "1-268"
        },
        {
            "id": "SA",
            "name": "Arabia Saudí",
            "code": "966"
        },
        {
            "id": "DZ",
            "name": "Argelia",
            "code": "213"
        },
        {
            "id": "AR",
            "name": "Argentina",
            "code": "54"
        },
        {
            "id": "AM",
            "name": "Armenia",
            "code": "374"
        },
        {
            "id": "AW",
            "name": "Aruba",
            "code": "297"
        },
        {
            "id": "AU",
            "name": "Australia",
            "code": "61"
        },
        {
            "id": "AT",
            "name": "Austria",
            "code": "43"
        },
        {
            "id": "AZ",
            "name": "Azerbaiyán",
            "code": "994"
        },
        {
            "id": "BS",
            "name": "Bahamas",
            "code": "1-242"
        },
        {
            "id": "BH",
            "name": "Bahrein",
            "code": "973"
        },
        {
            "id": "BD",
            "name": "Bangladesh",
            "code": "880"
        },
        {
            "id": "BB",
            "name": "Barbados",
            "code": "1-246"
        },
        {
            "id": "BE",
            "name": "Bélgica",
            "code": "32"
        },
        {
            "id": "BZ",
            "name": "Belice",
            "code": "501"
        },
        {
            "id": "BJ",
            "name": "Benín",
            "code": "229"
        },
        {
            "id": "BM",
            "name": "Bermudas",
            "code": "1-441"
        },
        {
            "id": "BY",
            "name": "Bielorrusia",
            "code": "375"
        },
        {
            "id": "BO",
            "name": "Bolivia",
            "code": "591"
        },
        {
            "id": "BA",
            "name": "Bosnia y Herzegovina",
            "code": "387"
        },
        {
            "id": "BW",
            "name": "Botswana",
            "code": "267"
        },
        {
            "id": "BR",
            "name": "Brasil",
            "code": "55"
        },
        {
            "id": "BN",
            "name": "Brunei",
            "code": "673"
        },
        {
            "id": "BG",
            "name": "Bulgaria",
            "code": "359"
        },
        {
            "id": "BF",
            "name": "Burkina Faso",
            "code": "226"
        },
        {
            "id": "BI",
            "name": "Burundi",
            "code": "257"
        },
        {
            "id": "BT",
            "name": "Bután",
            "code": "975"
        },
        {
            "id": "CV",
            "name": "Cabo Verde",
            "code": "238"
        },
        {
            "id": "KH",
            "name": "Camboya",
            "code": "855"
        },
        {
            "id": "CM",
            "name": "Camerún",
            "code": "237"
        },
        {
            "id": "CA",
            "name": "Canadá",
            "code": "1"
        },
        {
            "id": "QA",
            "name": "Catar",
            "code": "974"
        },
        {
            "id": "TD",
            "name": "Chad",
            "code": "235"
        },
        {
            "id": "CZ",
            "name": "Chequia",
            "code": "420"
        },
        {
            "id": "CL",
            "name": "Chile",
            "code": "56"
        },
        {
            "id": "CN",
            "name": "China",
            "code": "86"
        },
        {
            "id": "CY",
            "name": "Chipre",
            "code": "357"
        },
        {
            "id": "VA",
            "name": "Ciudad del Vaticano",
            "code": "379"
        },
        {
            "id": "CO",
            "name": "Colombia",
            "code": "57"
        },
        {
            "id": "KM",
            "name": "Comoras",
            "code": "269"
        },
        {
            "id": "CG",
            "name": "Congo",
            "code": "243"
        },
        {
            "id": "CD",
            "name": "Congo (Rep. Dem.)",
            "code": "242"
        },
        {
            "id": "KP",
            "name": "Corea del Norte",
            "code": "850"
        },
        {
            "id": "KR",
            "name": "Corea del Sur",
            "code": "82"
        },
        {
            "id": "CI",
            "name": "Costa de Marfil",
            "code": "225"
        },
        {
            "id": "CR",
            "name": "Costa Rica",
            "code": "506"
        },
        {
            "id": "HR",
            "name": "Croacia",
            "code": "385"
        },
        {
            "id": "CU",
            "name": "Cuba",
            "code": "53"
        },
        {
            "id": "CW",
            "name": "Curazao",
            "code": "599"
        },
        {
            "id": "DK",
            "name": "Dinamarca",
            "code": "45"
        },
        {
            "id": "DJ",
            "name": "Djibouti",
            "code": "253"
        },
        {
            "id": "DM",
            "name": "Dominica",
            "code": "1-767"
        },
        {
            "id": "EC",
            "name": "Ecuador",
            "code": "593"
        },
        {
            "id": "EG",
            "name": "Egipto",
            "code": "20"
        },
        {
            "id": "SV",
            "name": "El Salvador",
            "code": "503"
        },
        {
            "id": "AE",
            "name": "Emiratos Árabes Unidos",
            "code": "971"
        },
        {
            "id": "ER",
            "name": "Eritrea",
            "code": "291"
        },
        {
            "id": "SI",
            "name": "Eslovenia",
            "code": "386"
        },
        {
            "id": "ES",
            "name": "España",
            "code": "34"
        },
        {
            "id": "US",
            "name": "Estados Unidos",
            "code": "1"
        },
        {
            "id": "EE",
            "name": "Estonia",
            "code": "372"
        },
        {
            "id": "ET",
            "name": "Etiopía",
            "code": "251"
        },
        {
            "id": "PH",
            "name": "Filipinas",
            "code": "63"
        },
        {
            "id": "FI",
            "name": "Finlandia",
            "code": "358"
        },
        {
            "id": "FJ",
            "name": "Fiyi",
            "code": "679"
        },
        {
            "id": "FR",
            "name": "Francia",
            "code": "33"
        },
        {
            "id": "GA",
            "name": "Gabón",
            "code": "241"
        },
        {
            "id": "GM",
            "name": "Gambia",
            "code": "220"
        },
        {
            "id": "GE",
            "name": "Georgia",
            "code": "995"
        },
        {
            "id": "GH",
            "name": "Ghana",
            "code": "233"
        },
        {
            "id": "GI",
            "name": "Gibraltar",
            "code": "350"
        },
        {
            "id": "GR",
            "name": "Grecia",
            "code": "30"
        },
        {
            "id": "GD",
            "name": "Grenada",
            "code": "1-473"
        },
        {
            "id": "GL",
            "name": "Groenlandia",
            "code": "299"
        },
        {
            "id": "GP",
            "name": "Guadalupe",
            "code": "590"
        },
        {
            "id": "GU",
            "name": "Guam",
            "code": "1-671"
        },
        {
            "id": "GT",
            "name": "Guatemala",
            "code": "502"
        },
        {
            "id": "GF",
            "name": "Guayana Francesa",
            "code": "594"
        },
        {
            "id": "GG",
            "name": "Guernsey",
            "code": "44"
        },
        {
            "id": "GN",
            "name": "Guinea",
            "code": "224"
        },
        {
            "id": "GQ",
            "name": "Guinea Ecuatorial",
            "code": "240"
        },
        {
            "id": "GW",
            "name": "Guinea-Bisáu",
            "code": "245"
        },
        {
            "id": "GY",
            "name": "Guyana",
            "code": "592"
        },
        {
            "id": "HT",
            "name": "Haití",
            "code": "509"
        },
        {
            "id": "HN",
            "name": "Honduras",
            "code": "504"
        },
        {
            "id": "HK",
            "name": "Hong Kong",
            "code": "852"
        },
        {
            "id": "HU",
            "name": "Hungría",
            "code": "36"
        },
        {
            "id": "IN",
            "name": "India",
            "code": "91"
        },
        {
            "id": "ID",
            "name": "Indonesia",
            "code": "62"
        },
        {
            "id": "IQ",
            "name": "Irak",
            "code": "964"
        },
        {
            "id": "IR",
            "name": "Iran",
            "code": "98"
        },
        {
            "id": "IE",
            "name": "Irlanda",
            "code": "353"
        },
        {
            "id": "BV",
            "name": "Isla Bouvet",
            "code": "47"
        },
        {
            "id": "IM",
            "name": "Isla de Man",
            "code": "44"
        },
        {
            "id": "CX",
            "name": "Isla de Navidad",
            "code": "61"
        },
        {
            "id": "NF",
            "name": "Isla de Norfolk",
            "code": "672"
        },
        {
            "id": "IS",
            "name": "Islandia",
            "code": "354"
        },
        {
            "id": "KY",
            "name": "Islas Caimán",
            "code": "1-345"
        },
        {
            "id": "CC",
            "name": "Islas Cocos o Islas Keeling",
            "code": "61"
        },
        {
            "id": "CK",
            "name": "Islas Cook",
            "code": "682"
        },
        {
            "id": "FO",
            "name": "Islas Faroe",
            "code": "298"
        },
        {
            "id": "GS",
            "name": "Islas Georgias del Sur y Sandwich del Sur",
            "code": "500"
        },
        {
            "id": "HM",
            "name": "Islas Heard y McDonald",
            "code": "672"
        },
        {
            "id": "FK",
            "name": "Islas Malvinas",
            "code": "500"
        },
        {
            "id": "MP",
            "name": "Islas Marianas del Norte",
            "code": "1-670"
        },
        {
            "id": "MH",
            "name": "Islas Marshall",
            "code": "692"
        },
        {
            "id": "PN",
            "name": "Islas Pitcairn",
            "code": "870"
        },
        {
            "id": "SB",
            "name": "Islas Salomón",
            "code": "677"
        },
        {
            "id": "SJ",
            "name": "Islas Svalbard y Jan Mayen",
            "code": "47"
        },
        {
            "id": "TK",
            "name": "Islas Tokelau",
            "code": "690"
        },
        {
            "id": "TC",
            "name": "Islas Turks y Caicos",
            "code": "1-649"
        },
        {
            "id": "VI",
            "name": "Islas Vírgenes de los Estados Unidos",
            "code": "1-340"
        },
        {
            "id": "VG",
            "name": "Islas Vírgenes del Reino Unido",
            "code": "1-284"
        },
        {
            "id": "IL",
            "name": "Israel",
            "code": "972"
        },
        {
            "id": "IT",
            "name": "Italia",
            "code": "39"
        },
        {
            "id": "JM",
            "name": "Jamaica",
            "code": "1-876"
        },
        {
            "id": "JP",
            "name": "Japón",
            "code": "81"
        },
        {
            "id": "JE",
            "name": "Jersey",
            "code": "44"
        },
        {
            "id": "JO",
            "name": "Jordania",
            "code": "962"
        },
        {
            "id": "KZ",
            "name": "Kazajistán",
            "code": "7"
        },
        {
            "id": "KE",
            "name": "Kenia",
            "code": "254"
        },
        {
            "id": "KG",
            "name": "Kirguizistán",
            "code": "996"
        },
        {
            "id": "KI",
            "name": "Kiribati",
            "code": "686"
        },
        {
            "id": "XK",
            "name": "Kosovo",
            "code": "383"
        },
        {
            "id": "KW",
            "name": "Kuwait",
            "code": "965"
        },
        {
            "id": "LA",
            "name": "Laos",
            "code": "856"
        },
        {
            "id": "LS",
            "name": "Lesotho",
            "code": "266"
        },
        {
            "id": "LV",
            "name": "Letonia",
            "code": "371"
        },
        {
            "id": "LB",
            "name": "Líbano",
            "code": "961"
        },
        {
            "id": "LR",
            "name": "Liberia",
            "code": "231"
        },
        {
            "id": "LY",
            "name": "Libia",
            "code": "218"
        },
        {
            "id": "LI",
            "name": "Liechtenstein",
            "code": "423"
        },
        {
            "id": "LT",
            "name": "Lituania",
            "code": "370"
        },
        {
            "id": "LU",
            "name": "Luxemburgo",
            "code": "352"
        },
        {
            "id": "MO",
            "name": "Macao",
            "code": "853"
        },
        {
            "id": "MK",
            "name": "Macedonia del Norte",
            "code": "389"
        },
        {
            "id": "MG",
            "name": "Madagascar",
            "code": "261"
        },
        {
            "id": "MY",
            "name": "Malasia",
            "code": "60"
        },
        {
            "id": "MW",
            "name": "Malawi",
            "code": "265"
        },
        {
            "id": "MV",
            "name": "Maldivas",
            "code": "960"
        },
        {
            "id": "ML",
            "name": "Mali",
            "code": "223"
        },
        {
            "id": "MT",
            "name": "Malta",
            "code": "356"
        },
        {
            "id": "MA",
            "name": "Marruecos",
            "code": "212"
        },
        {
            "id": "MQ",
            "name": "Martinica",
            "code": "596"
        },
        {
            "id": "MU",
            "name": "Mauricio",
            "code": "230"
        },
        {
            "id": "MR",
            "name": "Mauritania",
            "code": "222"
        },
        {
            "id": "YT",
            "name": "Mayotte",
            "code": "262"
        },
        {
            "id": "MX",
            "name": "México",
            "code": "52"
        },
        {
            "id": "FM",
            "name": "Micronesia",
            "code": "691"
        },
        {
            "id": "MD",
            "name": "Moldavia",
            "code": "373"
        },
        {
            "id": "MC",
            "name": "Mónaco",
            "code": "377"
        },
        {
            "id": "MN",
            "name": "Mongolia",
            "code": "976"
        },
        {
            "id": "ME",
            "name": "Montenegro",
            "code": "382"
        },
        {
            "id": "MS",
            "name": "Montserrat",
            "code": "1-664"
        },
        {
            "id": "MZ",
            "name": "Mozambique",
            "code": "258"
        },
        {
            "id": "MM",
            "name": "Myanmar",
            "code": "95"
        },
        {
            "id": "NA",
            "name": "Namibia",
            "code": "264"
        },
        {
            "id": "NR",
            "name": "Nauru",
            "code": "674"
        },
        {
            "id": "NP",
            "name": "Nepal",
            "code": "977"
        },
        {
            "id": "NI",
            "name": "Nicaragua",
            "code": "505"
        },
        {
            "id": "NE",
            "name": "Níger",
            "code": "227"
        },
        {
            "id": "NG",
            "name": "Nigeria",
            "code": "234"
        },
        {
            "id": "NU",
            "name": "Niue",
            "code": "683"
        },
        {
            "id": "NO",
            "name": "Noruega",
            "code": "47"
        },
        {
            "id": "NC",
            "name": "Nueva Caledonia",
            "code": "687"
        },
        {
            "id": "NZ",
            "name": "Nueva Zelanda",
            "code": "64"
        },
        {
            "id": "OM",
            "name": "Omán",
            "code": "968"
        },
        {
            "id": "NL",
            "name": "Países Bajos",
            "code": "31"
        },
        {
            "id": "PK",
            "name": "Pakistán",
            "code": "92"
        },
        {
            "id": "PW",
            "name": "Palau",
            "code": "680"
        },
        {
            "id": "PS",
            "name": "Palestina",
            "code": "970"
        },
        {
            "id": "PA",
            "name": "Panamá",
            "code": "507"
        },
        {
            "id": "PG",
            "name": "Papúa Nueva Guinea",
            "code": "675"
        },
        {
            "id": "PY",
            "name": "Paraguay",
            "code": "595"
        },
        {
            "id": "PE",
            "name": "Perú",
            "code": "51"
        },
        {
            "id": "PF",
            "name": "Polinesia Francesa",
            "code": "689"
        },
        {
            "id": "PL",
            "name": "Polonia",
            "code": "48"
        },
        {
            "id": "PT",
            "name": "Portugal",
            "code": "351"
        },
        {
            "id": "PR",
            "name": "Puerto Rico",
            "code": "1"
        },
        {
            "id": "GB",
            "name": "Reino Unido",
            "code": "44"
        },
        {
            "id": "CF",
            "name": "República Centroafricana",
            "code": "236"
        },
        {
            "id": "ZA",
            "name": "República de Sudáfrica",
            "code": "27"
        },
        {
            "id": "DO",
            "name": "República Dominicana",
            "code": "1-809"
        },
        {
            "id": "SK",
            "name": "República Eslovaca",
            "code": "421"
        },
        {
            "id": "RE",
            "name": "Reunión",
            "code": "262"
        },
        {
            "id": "RW",
            "name": "Ruanda",
            "code": "250"
        },
        {
            "id": "RO",
            "name": "Rumania",
            "code": "40"
        },
        {
            "id": "RU",
            "name": "Rusia",
            "code": "7"
        },
        {
            "id": "EH",
            "name": "Sahara Occidental",
            "code": "212"
        },
        {
            "id": "MF",
            "name": "Saint Martin",
            "code": "590"
        },
        {
            "id": "WS",
            "name": "Samoa",
            "code": "685"
        },
        {
            "id": "AS",
            "name": "Samoa Americana",
            "code": "1-684"
        },
        {
            "id": "BL",
            "name": "San Bartolomé",
            "code": "590"
        },
        {
            "id": "KN",
            "name": "San Cristóbal y Nieves",
            "code": "1-869"
        },
        {
            "id": "SM",
            "name": "San Marino",
            "code": "378"
        },
        {
            "id": "PM",
            "name": "San Pedro y Miquelón",
            "code": "508"
        },
        {
            "id": "VC",
            "name": "San Vicente y Granadinas",
            "code": "1-784"
        },
        {
            "id": "SH",
            "name": "Santa Elena, Ascensión y Tristán de Acuña",
            "code": "290"
        },
        {
            "id": "LC",
            "name": "Santa Lucía",
            "code": "1-758"
        },
        {
            "id": "ST",
            "name": "Santo Tomé y Príncipe",
            "code": "239"
        },
        {
            "id": "SN",
            "name": "Senegal",
            "code": "221"
        },
        {
            "id": "RS",
            "name": "Serbia",
            "code": "381"
        },
        {
            "id": "SC",
            "name": "Seychelles",
            "code": "248"
        },
        {
            "id": "SL",
            "name": "Sierra Leone",
            "code": "232"
        },
        {
            "id": "SG",
            "name": "Singapur",
            "code": "65"
        },
        {
            "id": "SX",
            "name": "Sint Maarten",
            "code": "1-721"
        },
        {
            "id": "SY",
            "name": "Siria",
            "code": "963"
        },
        {
            "id": "SO",
            "name": "Somalia",
            "code": "252"
        },
        {
            "id": "LK",
            "name": "Sri Lanka",
            "code": "94"
        },
        {
            "id": "SZ",
            "name": "Suazilandia",
            "code": "268"
        },
        {
            "id": "SD",
            "name": "Sudán",
            "code": "249"
        },
        {
            "id": "SS",
            "name": "Sudán del Sur",
            "code": "211"
        },
        {
            "id": "SE",
            "name": "Suecia",
            "code": "46"
        },
        {
            "id": "CH",
            "name": "Suiza",
            "code": "41"
        },
        {
            "id": "SR",
            "name": "Surinam",
            "code": "597"
        },
        {
            "id": "TH",
            "name": "Tailandia",
            "code": "66"
        },
        {
            "id": "TW",
            "name": "Taiwán",
            "code": "886"
        },
        {
            "id": "TZ",
            "name": "Tanzania",
            "code": "255"
        },
        {
            "id": "TJ",
            "name": "Tayikistán",
            "code": "992"
        },
        {
            "id": "IO",
            "name": "Territorio Británico del Océano Índico",
            "code": "246"
        },
        {
            "id": "TF",
            "name": "Tierras Australes y Antárticas Francesas",
            "code": "262"
        },
        {
            "id": "TL",
            "name": "Timor Oriental",
            "code": "670"
        },
        {
            "id": "TG",
            "name": "Togo",
            "code": "228"
        },
        {
            "id": "TO",
            "name": "Tonga",
            "code": "676"
        },
        {
            "id": "TT",
            "name": "Trinidad y Tobago",
            "code": "1-868"
        },
        {
            "id": "TN",
            "name": "Túnez",
            "code": "216"
        },
        {
            "id": "TM",
            "name": "Turkmenistán",
            "code": "993"
        },
        {
            "id": "TR",
            "name": "Turquía",
            "code": "90"
        },
        {
            "id": "TV",
            "name": "Tuvalu",
            "code": "688"
        },
        {
            "id": "UA",
            "name": "Ucrania",
            "code": "380"
        },
        {
            "id": "UG",
            "name": "Uganda",
            "code": "256"
        },
        {
            "id": "UY",
            "name": "Uruguay",
            "code": "598"
        },
        {
            "id": "UZ",
            "name": "Uzbekistán",
            "code": "998"
        },
        {
            "id": "VU",
            "name": "Vanuatu",
            "code": "678"
        },
        {
            "id": "VE",
            "name": "Venezuela",
            "code": "58"
        },
        {
            "id": "VN",
            "name": "Vietnam",
            "code": "84"
        },
        {
            "id": "WF",
            "name": "Wallis y Futuna",
            "code": "681"
        },
        {
            "id": "YE",
            "name": "Yemen",
            "code": "967"
        },
        {
            "id": "ZM",
            "name": "Zambia",
            "code": "260"
        },
        {
            "id": "ZW",
            "name": "Zimbabue",
            "code": "263"
        }
    ],
    "fr": [
        {
            "id": "AF",
            "name": "Afghanistan",
            "code": "93"
        },
        {
            "id": "ZA",
            "name": "Afrique du Sud",
            "code": "27"
        },
        {
            "id": "AX",
            "name": "Ahvenanmaa",
            "code": "358"
        },
        {
            "id": "AL",
            "name": "Albanie",
            "code": "355"
        },
        {
            "id": "DZ",
            "name": "Algérie",
            "code": "213"
        },
        {
            "id": "DE",
            "name": "Allemagne",
            "code": "49"
        },
        {
            "id": "AD",
            "name": "Andorre",
            "code": "376"
        },
        {
            "id": "AO",
            "name": "Angola",
            "code": "244"
        },
        {
            "id": "AI",
            "name": "Anguilla",
            "code": "1-264"
        },
        {
            "id": "AQ",
            "name": "Antarctique",
            "code": "672"
        },
        {
            "id": "AG",
            "name": "Antigua-et-Barbuda",
            "code": "1-268"
        },
        {
            "id": "SA",
            "name": "Arabie Saoudite",
            "code": "966"
        },
        {
            "id": "AR",
            "name": "Argentine",
            "code": "54"
        },
        {
            "id": "AM",
            "name": "Arménie",
            "code": "374"
        },
        {
            "id": "AW",
            "name": "Aruba",
            "code": "297"
        },
        {
            "id": "AU",
            "name": "Australie",
            "code": "61"
        },
        {
            "id": "AT",
            "name": "Autriche",
            "code": "43"
        },
        {
            "id": "AZ",
            "name": "Azerbaïdjan",
            "code": "994"
        },
        {
            "id": "BS",
            "name": "Bahamas",
            "code": "1-242"
        },
        {
            "id": "BH",
            "name": "Bahreïn",
            "code": "973"
        },
        {
            "id": "BD",
            "name": "Bangladesh",
            "code": "880"
        },
        {
            "id": "BB",
            "name": "Barbade",
            "code": "1-246"
        },
        {
            "id": "BE",
            "name": "Belgique",
            "code": "32"
        },
        {
            "id": "BZ",
            "name": "Belize",
            "code": "501"
        },
        {
            "id": "BJ",
            "name": "Bénin",
            "code": "229"
        },
        {
            "id": "BM",
            "name": "Bermudes",
            "code": "1-441"
        },
        {
            "id": "BT",
            "name": "Bhoutan",
            "code": "975"
        },
        {
            "id": "BY",
            "name": "Biélorussie",
            "code": "375"
        },
        {
            "id": "MM",
            "name": "Birmanie",
            "code": "95"
        },
        {
            "id": "BO",
            "name": "Bolivie",
            "code": "591"
        },
        {
            "id": "BA",
            "name": "Bosnie-Herzégovine",
            "code": "387"
        },
        {
            "id": "BW",
            "name": "Botswana",
            "code": "267"
        },
        {
            "id": "BR",
            "name": "Brésil",
            "code": "55"
        },
        {
            "id": "BN",
            "name": "Brunei",
            "code": "673"
        },
        {
            "id": "BG",
            "name": "Bulgarie",
            "code": "359"
        },
        {
            "id": "BF",
            "name": "Burkina Faso",
            "code": "226"
        },
        {
            "id": "BI",
            "name": "Burundi",
            "code": "257"
        },
        {
            "id": "KH",
            "name": "Cambodge",
            "code": "855"
        },
        {
            "id": "CM",
            "name": "Cameroun",
            "code": "237"
        },
        {
            "id": "CA",
            "name": "Canada",
            "code": "1"
        },
        {
            "id": "CL",
            "name": "Chili",
            "code": "56"
        },
        {
            "id": "CN",
            "name": "Chine",
            "code": "86"
        },
        {
            "id": "CY",
            "name": "Chypre",
            "code": "357"
        },
        {
            "id": "VA",
            "name": "Cité du Vatican",
            "code": "379"
        },
        {
            "id": "CO",
            "name": "Colombie",
            "code": "57"
        },
        {
            "id": "KM",
            "name": "Comores",
            "code": "269"
        },
        {
            "id": "CG",
            "name": "Congo",
            "code": "243"
        },
        {
            "id": "CD",
            "name": "Congo (Rép. dém.)",
            "code": "242"
        },
        {
            "id": "KP",
            "name": "Corée du Nord",
            "code": "850"
        },
        {
            "id": "KR",
            "name": "Corée du Sud",
            "code": "82"
        },
        {
            "id": "CR",
            "name": "Costa Rica",
            "code": "506"
        },
        {
            "id": "CI",
            "name": "Côte d'Ivoire",
            "code": "225"
        },
        {
            "id": "HR",
            "name": "Croatie",
            "code": "385"
        },
        {
            "id": "CU",
            "name": "Cuba",
            "code": "53"
        },
        {
            "id": "CW",
            "name": "Curaçao",
            "code": "599"
        },
        {
            "id": "DK",
            "name": "Danemark",
            "code": "45"
        },
        {
            "id": "DJ",
            "name": "Djibouti",
            "code": "253"
        },
        {
            "id": "DM",
            "name": "Dominique",
            "code": "1-767"
        },
        {
            "id": "EG",
            "name": "Égypte",
            "code": "20"
        },
        {
            "id": "AE",
            "name": "Émirats arabes unis",
            "code": "971"
        },
        {
            "id": "EC",
            "name": "Équateur",
            "code": "593"
        },
        {
            "id": "ER",
            "name": "Érythrée",
            "code": "291"
        },
        {
            "id": "ES",
            "name": "Espagne",
            "code": "34"
        },
        {
            "id": "EE",
            "name": "Estonie",
            "code": "372"
        },
        {
            "id": "US",
            "name": "États-Unis",
            "code": "1"
        },
        {
            "id": "ET",
            "name": "Éthiopie",
            "code": "251"
        },
        {
            "id": "FJ",
            "name": "Fidji",
            "code": "679"
        },
        {
            "id": "FI",
            "name": "Finlande",
            "code": "358"
        },
        {
            "id": "FR",
            "name": "France",
            "code": "33"
        },
        {
            "id": "GA",
            "name": "Gabon",
            "code": "241"
        },
        {
            "id": "GM",
            "name": "Gambie",
            "code": "220"
        },
        {
            "id": "GE",
            "name": "Géorgie",
            "code": "995"
        },
        {
            "id": "GS",
            "name": "Géorgie du Sud-et-les Îles Sandwich du Sud",
            "code": "500"
        },
        {
            "id": "GH",
            "name": "Ghana",
            "code": "233"
        },
        {
            "id": "GI",
            "name": "Gibraltar",
            "code": "350"
        },
        {
            "id": "GR",
            "name": "Grèce",
            "code": "30"
        },
        {
            "id": "GD",
            "name": "Grenade",
            "code": "1-473"
        },
        {
            "id": "GL",
            "name": "Groenland",
            "code": "299"
        },
        {
            "id": "GP",
            "name": "Guadeloupe",
            "code": "590"
        },
        {
            "id": "GU",
            "name": "Guam",
            "code": "1-671"
        },
        {
            "id": "GT",
            "name": "Guatemala",
            "code": "502"
        },
        {
            "id": "GG",
            "name": "Guernesey",
            "code": "44"
        },
        {
            "id": "GN",
            "name": "Guinée",
            "code": "224"
        },
        {
            "id": "GQ",
            "name": "Guinée équatoriale",
            "code": "240"
        },
        {
            "id": "GW",
            "name": "Guinée-Bissau",
            "code": "245"
        },
        {
            "id": "GY",
            "name": "Guyana",
            "code": "592"
        },
        {
            "id": "GF",
            "name": "Guyane",
            "code": "594"
        },
        {
            "id": "HT",
            "name": "Haïti",
            "code": "509"
        },
        {
            "id": "HN",
            "name": "Honduras",
            "code": "504"
        },
        {
            "id": "HK",
            "name": "Hong Kong",
            "code": "852"
        },
        {
            "id": "HU",
            "name": "Hongrie",
            "code": "36"
        },
        {
            "id": "BV",
            "name": "Île Bouvet",
            "code": "47"
        },
        {
            "id": "CX",
            "name": "Île Christmas",
            "code": "61"
        },
        {
            "id": "IM",
            "name": "Île de Man",
            "code": "44"
        },
        {
            "id": "MU",
            "name": "Île Maurice",
            "code": "230"
        },
        {
            "id": "NF",
            "name": "Île Norfolk",
            "code": "672"
        },
        {
            "id": "KY",
            "name": "Îles Caïmans",
            "code": "1-345"
        },
        {
            "id": "CC",
            "name": "Îles Cocos",
            "code": "61"
        },
        {
            "id": "CK",
            "name": "Îles Cook",
            "code": "682"
        },
        {
            "id": "CV",
            "name": "Îles du Cap-Vert",
            "code": "238"
        },
        {
            "id": "FO",
            "name": "Îles Féroé",
            "code": "298"
        },
        {
            "id": "HM",
            "name": "Îles Heard-et-MacDonald",
            "code": "672"
        },
        {
            "id": "FK",
            "name": "Îles Malouines",
            "code": "500"
        },
        {
            "id": "MP",
            "name": "Îles Mariannes du Nord",
            "code": "1-670"
        },
        {
            "id": "MH",
            "name": "Îles Marshall",
            "code": "692"
        },
        {
            "id": "PN",
            "name": "Îles Pitcairn",
            "code": "870"
        },
        {
            "id": "SB",
            "name": "Îles Salomon",
            "code": "677"
        },
        {
            "id": "TC",
            "name": "Îles Turques-et-Caïques",
            "code": "1-649"
        },
        {
            "id": "VG",
            "name": "Îles Vierges britanniques",
            "code": "1-284"
        },
        {
            "id": "VI",
            "name": "Îles Vierges des États-Unis",
            "code": "1-340"
        },
        {
            "id": "IN",
            "name": "Inde",
            "code": "91"
        },
        {
            "id": "ID",
            "name": "Indonésie",
            "code": "62"
        },
        {
            "id": "IQ",
            "name": "Irak",
            "code": "964"
        },
        {
            "id": "IR",
            "name": "Iran",
            "code": "98"
        },
        {
            "id": "IE",
            "name": "Irlande",
            "code": "353"
        },
        {
            "id": "IS",
            "name": "Islande",
            "code": "354"
        },
        {
            "id": "IL",
            "name": "Israël",
            "code": "972"
        },
        {
            "id": "IT",
            "name": "Italie",
            "code": "39"
        },
        {
            "id": "JM",
            "name": "Jamaïque",
            "code": "1-876"
        },
        {
            "id": "JP",
            "name": "Japon",
            "code": "81"
        },
        {
            "id": "JE",
            "name": "Jersey",
            "code": "44"
        },
        {
            "id": "JO",
            "name": "Jordanie",
            "code": "962"
        },
        {
            "id": "KZ",
            "name": "Kazakhstan",
            "code": "7"
        },
        {
            "id": "KE",
            "name": "Kenya",
            "code": "254"
        },
        {
            "id": "KG",
            "name": "Kirghizistan",
            "code": "996"
        },
        {
            "id": "KI",
            "name": "Kiribati",
            "code": "686"
        },
        {
            "id": "XK",
            "name": "Kosovo",
            "code": "383"
        },
        {
            "id": "KW",
            "name": "Koweït",
            "code": "965"
        },
        {
            "id": "LA",
            "name": "Laos",
            "code": "856"
        },
        {
            "id": "LS",
            "name": "Lesotho",
            "code": "266"
        },
        {
            "id": "LV",
            "name": "Lettonie",
            "code": "371"
        },
        {
            "id": "LB",
            "name": "Liban",
            "code": "961"
        },
        {
            "id": "LR",
            "name": "Liberia",
            "code": "231"
        },
        {
            "id": "LY",
            "name": "Libye",
            "code": "218"
        },
        {
            "id": "LI",
            "name": "Liechtenstein",
            "code": "423"
        },
        {
            "id": "LT",
            "name": "Lituanie",
            "code": "370"
        },
        {
            "id": "LU",
            "name": "Luxembourg",
            "code": "352"
        },
        {
            "id": "MO",
            "name": "Macao",
            "code": "853"
        },
        {
            "id": "MK",
            "name": "Macédoine du Nord",
            "code": "389"
        },
        {
            "id": "MG",
            "name": "Madagascar",
            "code": "261"
        },
        {
            "id": "MY",
            "name": "Malaisie",
            "code": "60"
        },
        {
            "id": "MW",
            "name": "Malawi",
            "code": "265"
        },
        {
            "id": "MV",
            "name": "Maldives",
            "code": "960"
        },
        {
            "id": "ML",
            "name": "Mali",
            "code": "223"
        },
        {
            "id": "MT",
            "name": "Malte",
            "code": "356"
        },
        {
            "id": "MA",
            "name": "Maroc",
            "code": "212"
        },
        {
            "id": "MQ",
            "name": "Martinique",
            "code": "596"
        },
        {
            "id": "MR",
            "name": "Mauritanie",
            "code": "222"
        },
        {
            "id": "YT",
            "name": "Mayotte",
            "code": "262"
        },
        {
            "id": "MX",
            "name": "Mexique",
            "code": "52"
        },
        {
            "id": "FM",
            "name": "Micronésie",
            "code": "691"
        },
        {
            "id": "MD",
            "name": "Moldavie",
            "code": "373"
        },
        {
            "id": "MC",
            "name": "Monaco",
            "code": "377"
        },
        {
            "id": "MN",
            "name": "Mongolie",
            "code": "976"
        },
        {
            "id": "ME",
            "name": "Monténégro",
            "code": "382"
        },
        {
            "id": "MS",
            "name": "Montserrat",
            "code": "1-664"
        },
        {
            "id": "MZ",
            "name": "Mozambique",
            "code": "258"
        },
        {
            "id": "NA",
            "name": "Namibie",
            "code": "264"
        },
        {
            "id": "NR",
            "name": "Nauru",
            "code": "674"
        },
        {
            "id": "NP",
            "name": "Népal",
            "code": "977"
        },
        {
            "id": "NI",
            "name": "Nicaragua",
            "code": "505"
        },
        {
            "id": "NE",
            "name": "Niger",
            "code": "227"
        },
        {
            "id": "NG",
            "name": "Nigéria",
            "code": "234"
        },
        {
            "id": "NU",
            "name": "Niue",
            "code": "683"
        },
        {
            "id": "NO",
            "name": "Norvège",
            "code": "47"
        },
        {
            "id": "NC",
            "name": "Nouvelle-Calédonie",
            "code": "687"
        },
        {
            "id": "NZ",
            "name": "Nouvelle-Zélande",
            "code": "64"
        },
        {
            "id": "OM",
            "name": "Oman",
            "code": "968"
        },
        {
            "id": "UG",
            "name": "Ouganda",
            "code": "256"
        },
        {
            "id": "UZ",
            "name": "Ouzbékistan",
            "code": "998"
        },
        {
            "id": "PK",
            "name": "Pakistan",
            "code": "92"
        },
        {
            "id": "PW",
            "name": "Palaos (Palau)",
            "code": "680"
        },
        {
            "id": "PS",
            "name": "Palestine",
            "code": "970"
        },
        {
            "id": "PA",
            "name": "Panama",
            "code": "507"
        },
        {
            "id": "PG",
            "name": "Papouasie-Nouvelle-Guinée",
            "code": "675"
        },
        {
            "id": "PY",
            "name": "Paraguay",
            "code": "595"
        },
        {
            "id": "NL",
            "name": "Pays-Bas",
            "code": "31"
        },
        {
            "id": "PE",
            "name": "Pérou",
            "code": "51"
        },
        {
            "id": "PH",
            "name": "Philippines",
            "code": "63"
        },
        {
            "id": "PL",
            "name": "Pologne",
            "code": "48"
        },
        {
            "id": "PF",
            "name": "Polynésie française",
            "code": "689"
        },
        {
            "id": "PR",
            "name": "Porto Rico",
            "code": "1"
        },
        {
            "id": "PT",
            "name": "Portugal",
            "code": "351"
        },
        {
            "id": "QA",
            "name": "Qatar",
            "code": "974"
        },
        {
            "id": "CF",
            "name": "République centrafricaine",
            "code": "236"
        },
        {
            "id": "DO",
            "name": "République dominicaine",
            "code": "1-809"
        },
        {
            "id": "RE",
            "name": "Réunion",
            "code": "262"
        },
        {
            "id": "RO",
            "name": "Roumanie",
            "code": "40"
        },
        {
            "id": "GB",
            "name": "Royaume-Uni",
            "code": "44"
        },
        {
            "id": "RU",
            "name": "Russie",
            "code": "7"
        },
        {
            "id": "RW",
            "name": "Rwanda",
            "code": "250"
        },
        {
            "id": "EH",
            "name": "Sahara Occidental",
            "code": "212"
        },
        {
            "id": "BL",
            "name": "Saint-Barthélemy",
            "code": "590"
        },
        {
            "id": "KN",
            "name": "Saint-Christophe-et-Niévès",
            "code": "1-869"
        },
        {
            "id": "SM",
            "name": "Saint-Marin",
            "code": "378"
        },
        {
            "id": "MF",
            "name": "Saint-Martin",
            "code": "590"
        },
        {
            "id": "SX",
            "name": "Saint-Martin",
            "code": "1-721"
        },
        {
            "id": "PM",
            "name": "Saint-Pierre-et-Miquelon",
            "code": "508"
        },
        {
            "id": "VC",
            "name": "Saint-Vincent-et-les-Grenadines",
            "code": "1-784"
        },
        {
            "id": "SH",
            "name": "Sainte-Hélène, Ascension et Tristan da Cunha",
            "code": "290"
        },
        {
            "id": "LC",
            "name": "Sainte-Lucie",
            "code": "1-758"
        },
        {
            "id": "SV",
            "name": "Salvador",
            "code": "503"
        },
        {
            "id": "WS",
            "name": "Samoa",
            "code": "685"
        },
        {
            "id": "AS",
            "name": "Samoa américaines",
            "code": "1-684"
        },
        {
            "id": "ST",
            "name": "São Tomé et Príncipe",
            "code": "239"
        },
        {
            "id": "SN",
            "name": "Sénégal",
            "code": "221"
        },
        {
            "id": "RS",
            "name": "Serbie",
            "code": "381"
        },
        {
            "id": "SC",
            "name": "Seychelles",
            "code": "248"
        },
        {
            "id": "SL",
            "name": "Sierra Leone",
            "code": "232"
        },
        {
            "id": "SG",
            "name": "Singapour",
            "code": "65"
        },
        {
            "id": "SK",
            "name": "Slovaquie",
            "code": "421"
        },
        {
            "id": "SI",
            "name": "Slovénie",
            "code": "386"
        },
        {
            "id": "SO",
            "name": "Somalie",
            "code": "252"
        },
        {
            "id": "SD",
            "name": "Soudan",
            "code": "249"
        },
        {
            "id": "SS",
            "name": "Soudan du Sud",
            "code": "211"
        },
        {
            "id": "LK",
            "name": "Sri Lanka",
            "code": "94"
        },
        {
            "id": "SE",
            "name": "Suède",
            "code": "46"
        },
        {
            "id": "CH",
            "name": "Suisse",
            "code": "41"
        },
        {
            "id": "SR",
            "name": "Surinam",
            "code": "597"
        },
        {
            "id": "SJ",
            "name": "Svalbard et Jan Mayen",
            "code": "47"
        },
        {
            "id": "SZ",
            "name": "Swaziland",
            "code": "268"
        },
        {
            "id": "SY",
            "name": "Syrie",
            "code": "963"
        },
        {
            "id": "TJ",
            "name": "Tadjikistan",
            "code": "992"
        },
        {
            "id": "TW",
            "name": "Taïwan",
            "code": "886"
        },
        {
            "id": "TZ",
            "name": "Tanzanie",
            "code": "255"
        },
        {
            "id": "TD",
            "name": "Tchad",
            "code": "235"
        },
        {
            "id": "CZ",
            "name": "Tchéquie",
            "code": "420"
        },
        {
            "id": "TF",
            "name": "Terres australes et antarctiques françaises",
            "code": "262"
        },
        {
            "id": "IO",
            "name": "Territoire britannique de l'océan Indien",
            "code": "246"
        },
        {
            "id": "TH",
            "name": "Thaïlande",
            "code": "66"
        },
        {
            "id": "TL",
            "name": "Timor oriental",
            "code": "670"
        },
        {
            "id": "TG",
            "name": "Togo",
            "code": "228"
        },
        {
            "id": "TK",
            "name": "Tokelau",
            "code": "690"
        },
        {
            "id": "TO",
            "name": "Tonga",
            "code": "676"
        },
        {
            "id": "TT",
            "name": "Trinité-et-Tobago",
            "code": "1-868"
        },
        {
            "id": "TN",
            "name": "Tunisie",
            "code": "216"
        },
        {
            "id": "TM",
            "name": "Turkménistan",
            "code": "993"
        },
        {
            "id": "TR",
            "name": "Turquie",
            "code": "90"
        },
        {
            "id": "TV",
            "name": "Tuvalu",
            "code": "688"
        },
        {
            "id": "UA",
            "name": "Ukraine",
            "code": "380"
        },
        {
            "id": "UY",
            "name": "Uruguay",
            "code": "598"
        },
        {
            "id": "VU",
            "name": "Vanuatu",
            "code": "678"
        },
        {
            "id": "VE",
            "name": "Venezuela",
            "code": "58"
        },
        {
            "id": "VN",
            "name": "Viêt Nam",
            "code": "84"
        },
        {
            "id": "WF",
            "name": "Wallis-et-Futuna",
            "code": "681"
        },
        {
            "id": "YE",
            "name": "Yémen",
            "code": "967"
        },
        {
            "id": "ZM",
            "name": "Zambie",
            "code": "260"
        },
        {
            "id": "ZW",
            "name": "Zimbabwe",
            "code": "263"
        }
    ],
    "en": [
        {
            "id": "AF",
            "name": "Afghanistan",
            "code": "93"
        },
        {
            "id": "AX",
            "name": "Åland Islands",
            "code": "358"
        },
        {
            "id": "AL",
            "name": "Albania",
            "code": "355"
        },
        {
            "id": "DZ",
            "name": "Algeria",
            "code": "213"
        },
        {
            "id": "AS",
            "name": "American Samoa",
            "code": "1-684"
        },
        {
            "id": "AD",
            "name": "Andorra",
            "code": "376"
        },
        {
            "id": "AO",
            "name": "Angola",
            "code": "244"
        },
        {
            "id": "AI",
            "name": "Anguilla",
            "code": "1-264"
        },
        {
            "id": "AQ",
            "name": "Antarctica",
            "code": "672"
        },
        {
            "id": "AG",
            "name": "Antigua and Barbuda",
            "code": "1-268"
        },
        {
            "id": "AR",
            "name": "Argentina",
            "code": "54"
        },
        {
            "id": "AM",
            "name": "Armenia",
            "code": "374"
        },
        {
            "id": "AW",
            "name": "Aruba",
            "code": "297"
        },
        {
            "id": "AU",
            "name": "Australia",
            "code": "61"
        },
        {
            "id": "AT",
            "name": "Austria",
            "code": "43"
        },
        {
            "id": "AZ",
            "name": "Azerbaijan",
            "code": "994"
        },
        {
            "id": "BS",
            "name": "Bahamas",
            "code": "1-242"
        },
        {
            "id": "BH",
            "name": "Bahrain",
            "code": "973"
        },
        {
            "id": "BD",
            "name": "Bangladesh",
            "code": "880"
        },
        {
            "id": "BB",
            "name": "Barbados",
            "code": "1-246"
        },
        {
            "id": "BY",
            "name": "Belarus",
            "code": "375"
        },
        {
            "id": "BE",
            "name": "Belgium",
            "code": "32"
        },
        {
            "id": "BZ",
            "name": "Belize",
            "code": "501"
        },
        {
            "id": "BJ",
            "name": "Benin",
            "code": "229"
        },
        {
            "id": "BM",
            "name": "Bermuda",
            "code": "1-441"
        },
        {
            "id": "BT",
            "name": "Bhutan",
            "code": "975"
        },
        {
            "id": "BO",
            "name": "Bolivia",
            "code": "591"
        },
        {
            "id": "BA",
            "name": "Bosnia and Herzegovina",
            "code": "387"
        },
        {
            "id": "BW",
            "name": "Botswana",
            "code": "267"
        },
        {
            "id": "BV",
            "name": "Bouvet Island",
            "code": "47"
        },
        {
            "id": "BR",
            "name": "Brazil",
            "code": "55"
        },
        {
            "id": "IO",
            "name": "British Indian Ocean Territory",
            "code": "246"
        },
        {
            "id": "VG",
            "name": "British Virgin Islands",
            "code": "1-284"
        },
        {
            "id": "BN",
            "name": "Brunei",
            "code": "673"
        },
        {
            "id": "BG",
            "name": "Bulgaria",
            "code": "359"
        },
        {
            "id": "BF",
            "name": "Burkina Faso",
            "code": "226"
        },
        {
            "id": "BI",
            "name": "Burundi",
            "code": "257"
        },
        {
            "id": "KH",
            "name": "Cambodia",
            "code": "855"
        },
        {
            "id": "CM",
            "name": "Cameroon",
            "code": "237"
        },
        {
            "id": "CA",
            "name": "Canada",
            "code": "1"
        },
        {
            "id": "CV",
            "name": "Cape Verde",
            "code": "238"
        },
        {
            "id": "KY",
            "name": "Cayman Islands",
            "code": "1-345"
        },
        {
            "id": "CF",
            "name": "Central African Republic",
            "code": "236"
        },
        {
            "id": "TD",
            "name": "Chad",
            "code": "235"
        },
        {
            "id": "CL",
            "name": "Chile",
            "code": "56"
        },
        {
            "id": "CN",
            "name": "China",
            "code": "86"
        },
        {
            "id": "CX",
            "name": "Christmas Island",
            "code": "61"
        },
        {
            "id": "CC",
            "name": "Cocos (Keeling) Islands",
            "code": "61"
        },
        {
            "id": "CO",
            "name": "Colombia",
            "code": "57"
        },
        {
            "id": "KM",
            "name": "Comoros",
            "code": "269"
        },
        {
            "id": "CK",
            "name": "Cook Islands",
            "code": "682"
        },
        {
            "id": "CR",
            "name": "Costa Rica",
            "code": "506"
        },
        {
            "id": "HR",
            "name": "Croatia",
            "code": "385"
        },
        {
            "id": "CU",
            "name": "Cuba",
            "code": "53"
        },
        {
            "id": "CW",
            "name": "Curaçao",
            "code": "599"
        },
        {
            "id": "CY",
            "name": "Cyprus",
            "code": "357"
        },
        {
            "id": "CZ",
            "name": "Czechia",
            "code": "420"
        },
        {
            "id": "DK",
            "name": "Denmark",
            "code": "45"
        },
        {
            "id": "DJ",
            "name": "Djibouti",
            "code": "253"
        },
        {
            "id": "DM",
            "name": "Dominica",
            "code": "1-767"
        },
        {
            "id": "DO",
            "name": "Dominican Republic",
            "code": "1-809"
        },
        {
            "id": "CD",
            "name": "DR Congo",
            "code": "242"
        },
        {
            "id": "EC",
            "name": "Ecuador",
            "code": "593"
        },
        {
            "id": "EG",
            "name": "Egypt",
            "code": "20"
        },
        {
            "id": "SV",
            "name": "El Salvador",
            "code": "503"
        },
        {
            "id": "GQ",
            "name": "Equatorial Guinea",
            "code": "240"
        },
        {
            "id": "ER",
            "name": "Eritrea",
            "code": "291"
        },
        {
            "id": "EE",
            "name": "Estonia",
            "code": "372"
        },
        {
            "id": "SZ",
            "name": "Eswatini",
            "code": "268"
        },
        {
            "id": "ET",
            "name": "Ethiopia",
            "code": "251"
        },
        {
            "id": "FK",
            "name": "Falkland Islands",
            "code": "500"
        },
        {
            "id": "FO",
            "name": "Faroe Islands",
            "code": "298"
        },
        {
            "id": "FJ",
            "name": "Fiji",
            "code": "679"
        },
        {
            "id": "FI",
            "name": "Finland",
            "code": "358"
        },
        {
            "id": "FR",
            "name": "France",
            "code": "33"
        },
        {
            "id": "GF",
            "name": "French Guiana",
            "code": "594"
        },
        {
            "id": "PF",
            "name": "French Polynesia",
            "code": "689"
        },
        {
            "id": "TF",
            "name": "French Southern and Antarctic Lands",
            "code": "262"
        },
        {
            "id": "GA",
            "name": "Gabon",
            "code": "241"
        },
        {
            "id": "GM",
            "name": "Gambia",
            "code": "220"
        },
        {
            "id": "GE",
            "name": "Georgia",
            "code": "995"
        },
        {
            "id": "DE",
            "name": "Germany",
            "code": "49"
        },
        {
            "id": "GH",
            "name": "Ghana",
            "code": "233"
        },
        {
            "id": "GI",
            "name": "Gibraltar",
            "code": "350"
        },
        {
            "id": "GR",
            "name": "Greece",
            "code": "30"
        },
        {
            "id": "GL",
            "name": "Greenland",
            "code": "299"
        },
        {
            "id": "GD",
            "name": "Grenada",
            "code": "1-473"
        },
        {
            "id": "GP",
            "name": "Guadeloupe",
            "code": "590"
        },
        {
            "id": "GU",
            "name": "Guam",
            "code": "1-671"
        },
        {
            "id": "GT",
            "name": "Guatemala",
            "code": "502"
        },
        {
            "id": "GG",
            "name": "Guernsey",
            "code": "44"
        },
        {
            "id": "GN",
            "name": "Guinea",
            "code": "224"
        },
        {
            "id": "GW",
            "name": "Guinea-Bissau",
            "code": "245"
        },
        {
            "id": "GY",
            "name": "Guyana",
            "code": "592"
        },
        {
            "id": "HT",
            "name": "Haiti",
            "code": "509"
        },
        {
            "id": "HM",
            "name": "Heard Island and McDonald Islands",
            "code": "672"
        },
        {
            "id": "HN",
            "name": "Honduras",
            "code": "504"
        },
        {
            "id": "HK",
            "name": "Hong Kong",
            "code": "852"
        },
        {
            "id": "HU",
            "name": "Hungary",
            "code": "36"
        },
        {
            "id": "IS",
            "name": "Iceland",
            "code": "354"
        },
        {
            "id": "IN",
            "name": "India",
            "code": "91"
        },
        {
            "id": "ID",
            "name": "Indonesia",
            "code": "62"
        },
        {
            "id": "IR",
            "name": "Iran",
            "code": "98"
        },
        {
            "id": "IQ",
            "name": "Iraq",
            "code": "964"
        },
        {
            "id": "IE",
            "name": "Ireland",
            "code": "353"
        },
        {
            "id": "IM",
            "name": "Isle of Man",
            "code": "44"
        },
        {
            "id": "IL",
            "name": "Israel",
            "code": "972"
        },
        {
            "id": "IT",
            "name": "Italy",
            "code": "39"
        },
        {
            "id": "CI",
            "name": "Ivory Coast",
            "code": "225"
        },
        {
            "id": "JM",
            "name": "Jamaica",
            "code": "1-876"
        },
        {
            "id": "JP",
            "name": "Japan",
            "code": "81"
        },
        {
            "id": "JE",
            "name": "Jersey",
            "code": "44"
        },
        {
            "id": "JO",
            "name": "Jordan",
            "code": "962"
        },
        {
            "id": "KZ",
            "name": "Kazakhstan",
            "code": "7"
        },
        {
            "id": "KE",
            "name": "Kenya",
            "code": "254"
        },
        {
            "id": "KI",
            "name": "Kiribati",
            "code": "686"
        },
        {
            "id": "XK",
            "name": "Kosovo",
            "code": "383"
        },
        {
            "id": "KW",
            "name": "Kuwait",
            "code": "965"
        },
        {
            "id": "KG",
            "name": "Kyrgyzstan",
            "code": "996"
        },
        {
            "id": "LA",
            "name": "Laos",
            "code": "856"
        },
        {
            "id": "LV",
            "name": "Latvia",
            "code": "371"
        },
        {
            "id": "LB",
            "name": "Lebanon",
            "code": "961"
        },
        {
            "id": "LS",
            "name": "Lesotho",
            "code": "266"
        },
        {
            "id": "LR",
            "name": "Liberia",
            "code": "231"
        },
        {
            "id": "LY",
            "name": "Libya",
            "code": "218"
        },
        {
            "id": "LI",
            "name": "Liechtenstein",
            "code": "423"
        },
        {
            "id": "LT",
            "name": "Lithuania",
            "code": "370"
        },
        {
            "id": "LU",
            "name": "Luxembourg",
            "code": "352"
        },
        {
            "id": "MO",
            "name": "Macau",
            "code": "853"
        },
        {
            "id": "MG",
            "name": "Madagascar",
            "code": "261"
        },
        {
            "id": "MW",
            "name": "Malawi",
            "code": "265"
        },
        {
            "id": "MY",
            "name": "Malaysia",
            "code": "60"
        },
        {
            "id": "MV",
            "name": "Maldives",
            "code": "960"
        },
        {
            "id": "ML",
            "name": "Mali",
            "code": "223"
        },
        {
            "id": "MT",
            "name": "Malta",
            "code": "356"
        },
        {
            "id": "MH",
            "name": "Marshall Islands",
            "code": "692"
        },
        {
            "id": "MQ",
            "name": "Martinique",
            "code": "596"
        },
        {
            "id": "MR",
            "name": "Mauritania",
            "code": "222"
        },
        {
            "id": "MU",
            "name": "Mauritius",
            "code": "230"
        },
        {
            "id": "YT",
            "name": "Mayotte",
            "code": "262"
        },
        {
            "id": "MX",
            "name": "Mexico",
            "code": "52"
        },
        {
            "id": "FM",
            "name": "Micronesia",
            "code": "691"
        },
        {
            "id": "MD",
            "name": "Moldova",
            "code": "373"
        },
        {
            "id": "MC",
            "name": "Monaco",
            "code": "377"
        },
        {
            "id": "MN",
            "name": "Mongolia",
            "code": "976"
        },
        {
            "id": "ME",
            "name": "Montenegro",
            "code": "382"
        },
        {
            "id": "MS",
            "name": "Montserrat",
            "code": "1-664"
        },
        {
            "id": "MA",
            "name": "Morocco",
            "code": "212"
        },
        {
            "id": "MZ",
            "name": "Mozambique",
            "code": "258"
        },
        {
            "id": "MM",
            "name": "Myanmar",
            "code": "95"
        },
        {
            "id": "NA",
            "name": "Namibia",
            "code": "264"
        },
        {
            "id": "NR",
            "name": "Nauru",
            "code": "674"
        },
        {
            "id": "NP",
            "name": "Nepal",
            "code": "977"
        },
        {
            "id": "NL",
            "name": "Netherlands",
            "code": "31"
        },
        {
            "id": "NC",
            "name": "New Caledonia",
            "code": "687"
        },
        {
            "id": "NZ",
            "name": "New Zealand",
            "code": "64"
        },
        {
            "id": "NI",
            "name": "Nicaragua",
            "code": "505"
        },
        {
            "id": "NE",
            "name": "Niger",
            "code": "227"
        },
        {
            "id": "NG",
            "name": "Nigeria",
            "code": "234"
        },
        {
            "id": "NU",
            "name": "Niue",
            "code": "683"
        },
        {
            "id": "NF",
            "name": "Norfolk Island",
            "code": "672"
        },
        {
            "id": "KP",
            "name": "North Korea",
            "code": "850"
        },
        {
            "id": "MK",
            "name": "North Macedonia",
            "code": "389"
        },
        {
            "id": "MP",
            "name": "Northern Mariana Islands",
            "code": "1-670"
        },
        {
            "id": "NO",
            "name": "Norway",
            "code": "47"
        },
        {
            "id": "OM",
            "name": "Oman",
            "code": "968"
        },
        {
            "id": "PK",
            "name": "Pakistan",
            "code": "92"
        },
        {
            "id": "PW",
            "name": "Palau",
            "code": "680"
        },
        {
            "id": "PS",
            "name": "Palestine",
            "code": "970"
        },
        {
            "id": "PA",
            "name": "Panama",
            "code": "507"
        },
        {
            "id": "PG",
            "name": "Papua New Guinea",
            "code": "675"
        },
        {
            "id": "PY",
            "name": "Paraguay",
            "code": "595"
        },
        {
            "id": "PE",
            "name": "Peru",
            "code": "51"
        },
        {
            "id": "PH",
            "name": "Philippines",
            "code": "63"
        },
        {
            "id": "PN",
            "name": "Pitcairn Islands",
            "code": "870"
        },
        {
            "id": "PL",
            "name": "Poland",
            "code": "48"
        },
        {
            "id": "PT",
            "name": "Portugal",
            "code": "351"
        },
        {
            "id": "PR",
            "name": "Puerto Rico",
            "code": "1"
        },
        {
            "id": "QA",
            "name": "Qatar",
            "code": "974"
        },
        {
            "id": "CG",
            "name": "Republic of the Congo",
            "code": "243"
        },
        {
            "id": "RE",
            "name": "Réunion",
            "code": "262"
        },
        {
            "id": "RO",
            "name": "Romania",
            "code": "40"
        },
        {
            "id": "RU",
            "name": "Russia",
            "code": "7"
        },
        {
            "id": "RW",
            "name": "Rwanda",
            "code": "250"
        },
        {
            "id": "BL",
            "name": "Saint Barthélemy",
            "code": "590"
        },
        {
            "id": "SH",
            "name": "Saint Helena, Ascension and Tristan da Cunha",
            "code": "290"
        },
        {
            "id": "KN",
            "name": "Saint Kitts and Nevis",
            "code": "1-869"
        },
        {
            "id": "LC",
            "name": "Saint Lucia",
            "code": "1-758"
        },
        {
            "id": "MF",
            "name": "Saint Martin",
            "code": "590"
        },
        {
            "id": "PM",
            "name": "Saint Pierre and Miquelon",
            "code": "508"
        },
        {
            "id": "VC",
            "name": "Saint Vincent and the Grenadines",
            "code": "1-784"
        },
        {
            "id": "WS",
            "name": "Samoa",
            "code": "685"
        },
        {
            "id": "SM",
            "name": "San Marino",
            "code": "378"
        },
        {
            "id": "ST",
            "name": "São Tomé and Príncipe",
            "code": "239"
        },
        {
            "id": "SA",
            "name": "Saudi Arabia",
            "code": "966"
        },
        {
            "id": "SN",
            "name": "Senegal",
            "code": "221"
        },
        {
            "id": "RS",
            "name": "Serbia",
            "code": "381"
        },
        {
            "id": "SC",
            "name": "Seychelles",
            "code": "248"
        },
        {
            "id": "SL",
            "name": "Sierra Leone",
            "code": "232"
        },
        {
            "id": "SG",
            "name": "Singapore",
            "code": "65"
        },
        {
            "id": "SX",
            "name": "Sint Maarten",
            "code": "1-721"
        },
        {
            "id": "SK",
            "name": "Slovakia",
            "code": "421"
        },
        {
            "id": "SI",
            "name": "Slovenia",
            "code": "386"
        },
        {
            "id": "SB",
            "name": "Solomon Islands",
            "code": "677"
        },
        {
            "id": "SO",
            "name": "Somalia",
            "code": "252"
        },
        {
            "id": "ZA",
            "name": "South Africa",
            "code": "27"
        },
        {
            "id": "GS",
            "name": "South Georgia",
            "code": "500"
        },
        {
            "id": "KR",
            "name": "South Korea",
            "code": "82"
        },
        {
            "id": "SS",
            "name": "South Sudan",
            "code": "211"
        },
        {
            "id": "ES",
            "name": "Spain",
            "code": "34"
        },
        {
            "id": "LK",
            "name": "Sri Lanka",
            "code": "94"
        },
        {
            "id": "SD",
            "name": "Sudan",
            "code": "249"
        },
        {
            "id": "SR",
            "name": "Suriname",
            "code": "597"
        },
        {
            "id": "SJ",
            "name": "Svalbard and Jan Mayen",
            "code": "47"
        },
        {
            "id": "SE",
            "name": "Sweden",
            "code": "46"
        },
        {
            "id": "CH",
            "name": "Switzerland",
            "code": "41"
        },
        {
            "id": "SY",
            "name": "Syria",
            "code": "963"
        },
        {
            "id": "TW",
            "name": "Taiwan",
            "code": "886"
        },
        {
            "id": "TJ",
            "name": "Tajikistan",
            "code": "992"
        },
        {
            "id": "TZ",
            "name": "Tanzania",
            "code": "255"
        },
        {
            "id": "TH",
            "name": "Thailand",
            "code": "66"
        },
        {
            "id": "TL",
            "name": "Timor-Leste",
            "code": "670"
        },
        {
            "id": "TG",
            "name": "Togo",
            "code": "228"
        },
        {
            "id": "TK",
            "name": "Tokelau",
            "code": "690"
        },
        {
            "id": "TO",
            "name": "Tonga",
            "code": "676"
        },
        {
            "id": "TT",
            "name": "Trinidad and Tobago",
            "code": "1-868"
        },
        {
            "id": "TN",
            "name": "Tunisia",
            "code": "216"
        },
        {
            "id": "TR",
            "name": "Turkey",
            "code": "90"
        },
        {
            "id": "TM",
            "name": "Turkmenistan",
            "code": "993"
        },
        {
            "id": "TC",
            "name": "Turks and Caicos Islands",
            "code": "1-649"
        },
        {
            "id": "TV",
            "name": "Tuvalu",
            "code": "688"
        },
        {
            "id": "UG",
            "name": "Uganda",
            "code": "256"
        },
        {
            "id": "UA",
            "name": "Ukraine",
            "code": "380"
        },
        {
            "id": "AE",
            "name": "United Arab Emirates",
            "code": "971"
        },
        {
            "id": "GB",
            "name": "United Kingdom",
            "code": "44"
        },
        {
            "id": "US",
            "name": "United States",
            "code": "1"
        },
        {
            "id": "VI",
            "name": "United States Virgin Islands",
            "code": "1-340"
        },
        {
            "id": "UY",
            "name": "Uruguay",
            "code": "598"
        },
        {
            "id": "UZ",
            "name": "Uzbekistan",
            "code": "998"
        },
        {
            "id": "VU",
            "name": "Vanuatu",
            "code": "678"
        },
        {
            "id": "VA",
            "name": "Vatican City",
            "code": "379"
        },
        {
            "id": "VE",
            "name": "Venezuela",
            "code": "58"
        },
        {
            "id": "VN",
            "name": "Vietnam",
            "code": "84"
        },
        {
            "id": "WF",
            "name": "Wallis and Futuna",
            "code": "681"
        },
        {
            "id": "EH",
            "name": "Western Sahara",
            "code": "212"
        },
        {
            "id": "YE",
            "name": "Yemen",
            "code": "967"
        },
        {
            "id": "ZM",
            "name": "Zambia",
            "code": "260"
        },
        {
            "id": "ZW",
            "name": "Zimbabwe",
            "code": "263"
        }
    ]
};
