import React, { useState, useEffect } from "react";
import { defer } from "rxjs";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import { CheckSquare, XSquare } from "phosphor-react";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { NavBar } from "../../components/NavBar";
import CursoModal from "./CursoModal";
import { getCursosConfigList } from "../../services/cursos.service";
import { countryToFlag } from "../../services/countries.service";
import HeaderActions from "../../components/Header-actions";

const Cursos = () => {
  const [cursosList, setCursosList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cursoSelected, setCursoSelected] = useState({});
  const handleModal = (cell) => {
    setCursoSelected(cell.row);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const subscription = defer(() => getCursosConfigList()).subscribe(
      (list) => {
        setCursosList(list);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const localRefresh = async (updatedCurso) => {
    setLoading(true);
    const idxToRefresh = cursosList.findIndex((c) => c.id === updatedCurso.id);
    let updatedList = [];
    if (idxToRefresh > -1) {
      updatedList = [
        ...cursosList.slice(0, idxToRefresh),
        updatedCurso,
        ...cursosList.slice(idxToRefresh + 1),
      ];
    } else {
      updatedList = [...cursosList, updatedCurso];
    }
    setCursosList(updatedList);
    setLoading(false);
  };

  const fullRefresh = () => {
    setLoading(true);
    getCursosConfigList().then((list) => {
      setCursosList(list);
      setLoading(false);
    });
  };

  const columns = [
    {
      field: "codigo",
      headerName: "Codigo",
      width: 90,
      renderCell: (params) => {
        return <strong>{params.row.codigo}</strong>;
      },
    },
    {
      field: "titulo",
      headerName: "Titulo",
      width: 320,
      renderCell: (params) => {
        const title = params.row.titulo;
        return (
          <Tooltip title={title}>
            <span>{title}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "tipo_curso",
      headerName: "Tipo",
      width: 120,
    },
    {
      field: "validez_meses",
      headerName: "Validez",
      description: "Validez en meses del curso.",
      width: 80,
    },
    {
      field: "tituloES",
      headerName: `Titulo ${countryToFlag("ES")}`,
      description: "Titulo del curso en Español.",
      renderCell: (params) => {
        return params.row.lang["es"] ? (
          <Tooltip title={params.row.lang["es"]}>
            <CheckSquare size={24} color="#0e9524" />
          </Tooltip>
        ) : (
          <XSquare size={24} color="#ea1f1f" />
        );
      },
    },
    {
      field: "tituloFR",
      headerName: `Titulo ${countryToFlag("FR")}`,
      description: "Titulo del curso en Frances.",
      renderCell: (params) => {
        return params.row.lang["fr"] ? (
          <Tooltip title={params.row.lang["fr"]}>
            <CheckSquare size={24} color="#0e9524" />
          </Tooltip>
        ) : (
          <XSquare size={24} color="#ea1f1f" />
        );
      },
    },
    {
      field: "tituloHE",
      headerName: `Titulo ${countryToFlag("IL")}`,
      description: "Titulo del curso en Hebreo.",
      renderCell: (params) => {
        return params.row.lang["he"] ? (
          <Tooltip title={params.row.lang["he"]}>
            <CheckSquare size={24} color="#0e9524" />
          </Tooltip>
        ) : (
          <XSquare size={24} color="#ea1f1f" />
        );
      },
    },
    {
      field: "tituloAR",
      headerName: `Titulo ${countryToFlag("MA")}`,
      description: "Titulo del curso en Arabe.",
      renderCell: (params) => {
        return params.row.lang["ar"] ? (
          <Tooltip title={params.row.lang["ar"]}>
            <CheckSquare size={24} color="#0e9524" />
          </Tooltip>
        ) : (
          <XSquare size={24} color="#ea1f1f" />
        );
      },
    },
  ];

  return (
    <Box sx={{ display: "flex", mt: 4, mb: 4 }}>
      <NavBar selected="Cursos" />
      <Container maxWidth="lg">
        <Header title="Cursos" subtitle="Listado de Cursos" />
        <HeaderActions
          addItem={() => {
            setCursoSelected({});
            setIsModalOpen(true);
          }}
          addItemText="Agregar Curso"
          refresh={fullRefresh}
        />
        <CursoModal
          curso={cursoSelected}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          refresh={localRefresh}
        />
        <DataGrid
          rows={cursosList}
          columns={columns}
          sx={{ width: "100%", height: 600 }}
          loading={isLoading}
          autoHeight
          pageSize={15}
          rowsPerPageOptions={[15]}
          onCellClick={handleModal}
        />
      </Container>
    </Box>
  );
};

export default Cursos;
