import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { Loader } from "../../components/controls/Loader";

import { saveDocente } from "../../services/usuarios.service";
import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const DocenteModal = ({ item, isOpen, setIsOpen, refresh }) => {
  const initialFormState = () => {
    return {
      correo: "",
      nombre: "",
      apellidos: "",
      profileURL: "",
      esDocente: true,
      recursos: "",
      acceso_recursos: "",
      edicionesAceptadasDocente: [],
      recursos_disponibles: [],
    };
  };
  const [isCreation, setIsCreation] = useState(true);
  const [formData, setFormData] = useState(initialFormState());
  const [isLoading, setLoading] = useState(true);
  const [inputError, setInputError] = useState({});

  useEffect(() => {
    setLoading(true);
    if (item && Object.keys(item).length > 0) {
      setFormData(item);
      setIsCreation(false);
    } else {
      setFormData(initialFormState());
      setIsCreation(true);
    }
    setLoading(false);
  }, [isOpen, item]);

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleClose = () => {
    setIsOpen(false);
    setInputError({});
  };
  const handleSave = async () => {
    setLoading(true);
    const res = await saveDocente(formData);
    if (res.success) {
      refresh(res.data);
      handleClose();
    } else {
      setInputError({ error: `Ocurrio un error: ${res.error}` });
    }
    setLoading(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {formData.correo ? `Docente: ${formData.correo}` : "Nuevo Docente"}
      </DialogTitle>
      <DialogContent sx={{ pt: "16px !important" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Avatar
              alt="Foto de perfil"
              src={formData.profileURL}
              sx={{ width: 160, height: 160 }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="nombre"
              name="nombre"
              label="Nombre"
              fullWidth
              value={formData.nombre}
              onChange={onInputChange}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="apellidos"
              label="Apellidos"
              name="apellidos"
              fullWidth
              value={formData.apellidos}
              onChange={onInputChange}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="correo"
              name="correo"
              label="Correo"
              fullWidth
              value={formData.correo}
              onChange={onInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={6}></Grid>

          <Grid item xs={6}>
            <TextField
              id="lugar"
              name="lugar"
              label="Lugar"
              fullWidth
              value={formData.lugar}
              onChange={onInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="centros"
              name="centros"
              label="Centros"
              fullWidth
              value={formData.centros}
              onChange={onInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ fontSize: "small" }}>
              Permisos:
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="docente-label">Docente</InputLabel>
              <Select
                labelId="docente-label"
                id="docente-select"
                label="Docente"
                name="esDocente"
                value={formData.esDocente}
                onChange={onInputChange}
                fullWidth
                disabled={isLoading}
              >
                <MenuItem value={true}>SI</MenuItem>
                <MenuItem value={false}>NO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={12}>
            <Autocomplete
              id="recursos_disponibles-auto-chip"
              name="recursos_disponibles"
              multiple
              value={formData.recursos_disponibles}
              onChange={(event, newValue) => {
                setFormData({ ...formData, recursos_disponibles: newValue });
              }}
              options={formData.recursos_disponibles}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label="Recursos disponibles" />
              )}
            />
          </Grid>

          {!!inputError.error ? (
            <Grid item xs={12}>
              <span style={{ color: "#ff0000" }}>{inputError.error}</span>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {isLoading && <Loader />}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cerrar
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={isLoading}>
          {isCreation ? "Crear Usuario" : "Guardar cambios"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocenteModal;
