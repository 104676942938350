import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  if (!currentUser) {
    // user is not authenticated redirect to LOGIN
    return <Navigate to="/login" />;
  }
  return children;
};
