import React from "react";
import { Box, CircularProgress } from "@mui/material";

export const Loader = () => {
    return (
    <>
    <Box position="absolute" top='0' bottom='0' left='0' right='0' display='flex' justifyContent='center' alignItems='center' sx={{backgroundColor: 'rgba(255,255,255,0.6)', zIndex:'99' }}>
        <CircularProgress />
    </Box>

    </>)
}