import { firestore, storage } from "../firebase";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";

const CONFIG_COL = "config";

export const getConfigurations = async () => {
  const colRef = await getDocs(collection(firestore, CONFIG_COL));
  const configList = colRef.empty ? [] : colRef.docs;
  return configList.map((c) => {
    return { ...c.data(), id: c.id };
  });
};

export const getTipoCursos = async () => {
  const docRef = doc(firestore, CONFIG_COL, "cursos");
  return getDoc(docRef)
    .then((doc) => {
      return doc.exists() ? doc.data().tipo_cursos : [];
    })
    .catch((err) => {
      console.error("Error obteniendo configuracion de tipos de cursos: ", err);
      return [];
    });
};

export const getStorageURL = async (path) => {
  const url = await getDownloadURL(ref(storage, path));
  return url;
  //return `https://firebasestorage.googleapis.com/v0/b/${buckerUrl}/o/${path}`;
};
