import { createTheme } from "@mui/material/styles";

export const mainTheme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          background: "#F0F5FB !important",
        },
      },
    },
  },
  palette: {
    type: "light",
    primary: {
      main: "#0072d2",
    },
    secondary: {
      main: "#007FFF",
    },
    text: {
      primary: "#132F4C",
      secondary: "#5f6b7b",
      disabled: "#5b5b5e",
      hint: "#5f6267",
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),

    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
      fontSize: "1.5rem",
    },
    h4: {
      fontWeight: 500,
      fontSize: "1rem",
    },
    h5: {
      fontWeight: 700,
      fontSize: "1rem",
    },
    h6: {
      fontWeight: 500,
    },
    subtitle2: {
      textTransform: "uppercase",
      fontWeight: 500,
      fontSize: "1rem",
      color: "#70839B",
    },
    body2: {
      color: "#5f6b7b",
    },
  },
  header: {
    backgroundColor: "rgb(240, 247, 255)",
    borderColor: "rgb(0, 127, 255)",
    textColor: "#0072d2", //"rgb(0, 127, 255)",
    subtextColor: "#79aff7",
  },
  sideBar: {
    width: 200,
    selectedColor: "rgb(240, 247, 255) !important",
    borderColor: "rgb(0, 127, 255)",
  },
});
