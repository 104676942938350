import React from "react";
import { useTheme } from "@mui/material";
import { useAuth } from "../context/AuthContext";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import {
  Book,
  Buildings,
  ChalkboardTeacher,
  CurrencyCircleDollar,
  House,
  SignOut,
  Calendar,
  Receipt,
  Student,
  Notebook,
  GearSix,
  UserCirclePlus,
} from "phosphor-react";
import { Link } from "react-router-dom";

export const NavBar = ({ selected }) => {
  const { logout } = useAuth();
  const theme = useTheme();
  const sideBarTheme = theme.sideBar;

  return (
    <Drawer
      sx={{
        width: sideBarTheme.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: sideBarTheme.width,
          boxSizing: "border-box",
        },
        "& .Mui-selected": {
          backgroundColor: sideBarTheme.backgroundColor,
          borderColor: sideBarTheme.borderColor,
          borderStyle: "solid",
          borderWidth: "1px",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/"
            selected={selected === "Dashboard"}
          >
            <House size={24} />
            <ListItemText primary="Inicio" />
          </ListItemButton>
        </ListItem>
        <Divider />

        {/* Administracion */}
        <ListItem disablePadding>
          <ListItemButton disabled>
            <Calendar size={24} />
            <ListItemText primary="Calendario" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton disabled>
            <Receipt size={24} />
            <ListItemText primary="Propuestas" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/actividad-docentes"
            selected={selected === "Actividad Docentes"}
          >
            <Receipt size={24} />
            <ListItemText primary="Actividad Docentes" />
          </ListItemButton>
        </ListItem>

        {/* Usuarios */}
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/administradores"
            selected={selected === "Administradores"}
          >
            <UserCirclePlus size={24} />
            <ListItemText primary="Administradores" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton disabled>
            <Student size={24} />
            <ListItemText primary="Alumnos" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/docentes"
            selected={selected === "Docentes"}
          >
            <ChalkboardTeacher size={24} />
            <ListItemText primary="Docentes" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/clientes"
            selected={selected === "Clientes"}
          >
            <CurrencyCircleDollar size={24} />
            <ListItemText primary="Clientes" />
          </ListItemButton>
        </ListItem>

        {/* Configuracion */}
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/centros"
            selected={selected === "Centros"}
          >
            <Buildings size={24} />
            <ListItemText primary="Centros" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/cursos"
            selected={selected === "Cursos"}
          >
            <Book size={24} />
            <ListItemText primary="Cursos" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/versiones-cursos"
            selected={selected === "Versiones Cursos"}
          >
            <Notebook size={24} />
            <ListItemText primary="Versiones Cursos" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            disabled
            component={Link}
            to="/ediciones"
            selected={selected === "Ediciones"}
          >
            <Book size={24} />
            <ListItemText primary="Ediciones" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            disabled
            component={Link}
            to="/config-general"
            selected={selected === "Configuracion"}
          >
            <GearSix size={24} />
            <ListItemText primary="Configuracion" />
          </ListItemButton>
        </ListItem>

        {/* <ListItem disablePadding>
          <ListItemButton>
            <Exam size={24} />
            <ListItemText primary="Certificados" />
          </ListItemButton>
        </ListItem> */}

        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={() => logout()}>
            <SignOut size={24} />
            <ListItemText primary="Cerrar Sesion"></ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};
