import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { Loader } from "../../components/controls/Loader";
import { getCodigoCursos } from "../../services/cursos.service";
import {
  createVersion,
  saveVersion,
} from "../../services/cursos.versiones.service";
import Contenidos from "./Contenidos";
import { Typography } from "@mui/material";

const VersionModal = ({ item, isOpen, setIsOpen, refresh }) => {
  const initialFormState = () => {
    const defaultDate = new Date().toISOString().split("T")[0];
    return {
      codigo: "",
      version: "",
      desde: defaultDate,
      horas: 0,
      contenidos: {
        en: "",
        he: "",
        fr: "",
        ar: "",
        es: "",
      },
      contenidosTexto: {
        en: "",
        he: "",
        fr: "",
        ar: "",
        es: "",
      },
    };
  };
  const [isCreation, setIsCreation] = useState(true);
  const [formData, setFormData] = useState(initialFormState());
  const [isLoading, setLoading] = useState(true);
  const [inputError, setInputError] = useState({});
  const [codigosCurso, setCodigosCurso] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (item && Object.keys(item).length > 0) {
      setFormData({ ...initialFormState(), ...item });
      setIsCreation(false);
    } else {
      setFormData(initialFormState());
      setIsCreation(true);
    }
    setLoading(false);
  }, [isOpen, item]);

  useEffect(() => {
    getCodigoCursos().then((data) => {
      setCodigosCurso(data || []);
    });
  }, []);

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const setContenidos = (lang, contenido) => {
    setFormData({
      ...formData,
      contenidosTexto: {
        ...formData.contenidosTexto,
        [lang]: JSON.stringify(contenido),
      },
    });
  };

  const handleClose = () => {
    setIsOpen(false);
    setInputError({});
  };

  const handleSave = async () => {
    setLoading(true);
    if (validateInput()) {
      const res = isCreation
        ? await createVersion(formData)
        : await saveVersion(formData);
      if (res.success) {
        refresh(res.data);
        handleClose();
      } else {
        setInputError({ error: `Ocurrio un error: ${res.error}` });
      }
    }
    setLoading(false);
  };

  const validateInput = () => {
    const tempError = {};
    tempError.codigo = formData.codigo ? "" : "El codigo es requerido";
    tempError.version = formData.version ? "" : "La version es requerida";
    tempError.desde = formData.desde ? "" : "Fecha desde es requerido";
    tempError.horas =
      formData.horas && formData.horas > 0
        ? ""
        : "Las horas del curso son requeridas";

    setInputError(tempError);
    return Object.values(tempError).every((e) => !e);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {formData.id
          ? `${formData.codigo} version ${formData.version}`
          : "Nueva version de curso"}
      </DialogTitle>
      <DialogContent sx={{ pt: "16px !important" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="codigo-label" size="small" required>
                Codigo
              </InputLabel>
              <Select
                labelId="codigo-label"
                id="codigo-select"
                label="Codigo"
                name="codigo"
                value={formData.codigo}
                onChange={onInputChange}
                size="small"
                required
                disabled={isLoading || !!formData.id}
              >
                {codigosCurso.map((t) => (
                  <MenuItem key={t} value={t}>
                    {t}
                  </MenuItem>
                ))}
              </Select>
              {!!inputError.codigo ? (
                <FormHelperText style={{ color: "#ff0000" }}>
                  {inputError.codigo}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="version"
              name="version"
              label="Version"
              fullWidth
              size="small"
              value={formData.version}
              onChange={onInputChange}
              disabled={isLoading || !!formData.id}
              error={!!inputError["version"]}
              helperText={inputError["version"]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="desde"
              label="Desde"
              name="desde"
              fullWidth
              size="small"
              value={formData.desde}
              onChange={onInputChange}
              type="date"
              disabled={isLoading}
              error={!!inputError["desde"]}
              helperText={inputError["desde"]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="horas"
              label="Horas"
              name="horas"
              type="number"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={formData.horas}
              onChange={onInputChange}
              disabled={isLoading}
              error={!!inputError["horas"]}
              helperText={inputError["horas"]}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ fontSize: "small" }}>
              <strong>Contenidos:</strong>
            </Typography>
          </Grid>
          <Contenidos
            contenidos={formData.contenidosTexto}
            setContenidos={setContenidos}
            isLoading={isLoading}
            setLoading={setLoading}
            isOpen={isOpen}
          />
          <Grid></Grid>

          {!!inputError.error ? (
            <Grid item xs={12}>
              <span style={{ color: "#ff0000" }}>{inputError.error}</span>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {isLoading && <Loader />}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cerrar
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={isLoading}>
          {isCreation ? "Crear version" : "Guardar cambios"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VersionModal;
