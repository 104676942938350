import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import intl from "react-intl-universal";
import {
  AuthStyledContainer,
  ChildrenContainer,
} from "../components/AuthStyledContainer";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState("");
  const navigate = useNavigate();

  const validate = () => {
    let temp = { ...inputError };

    temp.email = email ? "" : intl.get("validations.required");
    if (email) {
      temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email)
        ? ""
        : intl.get("validations.invalid-email");
    }
    temp.password = password ? "" : intl.get("validations.required");

    setInputError({
      ...temp,
    });
    const isValid =
      email && password && Object.values(temp).every((x) => x === "");

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    try {
      setError("");
      setLoading(true);
      const { success, errorMsg } = await login(email, password.trim());
      if (!success) {
        setError(errorMsg);
        setLoading(false);
        return;
      }
      setLoading(false);
      navigate("/");
    } catch (err) {
      console.log(err);
      setError(intl.get("validations.login-error"));
      setLoading(false);
    }
  };

  return (
    <>
      <AuthStyledContainer isLoading={loading} error={error}>
        <form onSubmit={handleSubmit} noValidate>
          <ChildrenContainer>
            <Typography variant="h3">{intl.get("login.title")}</Typography>

            <TextField
              autoComplete="email"
              name="email"
              variant="outlined"
              fullWidth
              id="email"
              label={intl.get("login.email-label")}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              disabled={loading}
              {...(inputError["email"] && {
                error: true,
                helperText: inputError["email"],
              })}
            />
            <TextField
              autoComplete="new-password"
              name="password"
              variant="outlined"
              fullWidth
              id="password"
              label={intl.get("login.password-label")}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              disabled={loading}
              {...(inputError["password"] && {
                error: true,
                helperText: inputError["password"],
              })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {intl.get("login.button-label")}
            </Button>
          </ChildrenContainer>
        </form>
      </AuthStyledContainer>
    </>
  );
}
