import { firestore } from "../firebase";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  setDoc,
  query,
  orderBy,
  limit,
  where,
} from "firebase/firestore";

const CENTROS_COL = "centros.config";

export const getCentrosList = async () => {
  const centros = await getCentros();
  return centros.map((c) => {
    return { ...c.data(), id: c.id };
  });
};

const getCentros = async () => {
  const colRef = await getDocs(collection(firestore, CENTROS_COL));
  return colRef.empty ? [] : colRef.docs;
};

const getCentroByCodigo = async (codigo) => {
  try {
    const colRef = collection(firestore, CENTROS_COL);
    const q = query(colRef, where("codigo", "==", codigo.toUpperCase()));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.docs || querySnapshot.size === 0) {
      return null;
    }
    return querySnapshot.docs[0].data();
  } catch (err) {
    console.error("Error obteniendo centro", err);
    return null;
  }
};

const getNextId = async () => {
  const colRef = collection(firestore, CENTROS_COL);
  const q = query(colRef, orderBy("id", "desc"), limit(1));
  const querySnapshot = await getDocs(q);
  return querySnapshot.empty ? 1 : parseInt(querySnapshot.docs[0].id) + 1;
};

export const saveCentro = async (data) => {
  const cursoDoc = doc(firestore, CENTROS_COL, data.id);
  try {
    await updateDoc(cursoDoc, data);
    return { success: true, data: data };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false, error: err };
  }
};

export const createCentro = async (data) => {
  const lastId = await getNextId();
  const centroDoc = doc(firestore, CENTROS_COL, lastId.toString());
  if (await getCentroByCodigo(data.codigo)) {
    return {
      success: false,
      error: `Ya existe un centro con codigo ${data.codigo}`,
    };
  }
  try {
    data = { ...data, id: lastId, codigo: data.codigo.toUpperCase() };
    await setDoc(centroDoc, data);
    return { success: true, data };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false, error: err };
  }
};
