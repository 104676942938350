import { ArrowClockwise } from "phosphor-react";
import { Plus } from "phosphor-react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const HeaderActions = ({ refresh, addItem, addItemText }) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
      mb={2}
    >
      <Button variant="outlined" onClick={refresh}>
        <ArrowClockwise size={24} />
      </Button>
      {addItem ? (
        <Button
          variant="contained"
          onClick={addItem}
          startIcon={<Plus size={20} />}
        >
          {addItemText}
        </Button>
      ) : (
        ""
      )}
    </Stack>
  );
};

export default HeaderActions;
