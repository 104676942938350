import React, { useState, useEffect } from "react";
import { defer } from "rxjs";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { NavBar } from "../../components/NavBar";
import ClienteModal from "./ClienteModal";
import { getClientesList } from "../../services/clientes.service";
import HeaderActions from "../../components/Header-actions";
import Tooltip from "@mui/material/Tooltip";

const Clientes = () => {
  const [itemsList, setItemsList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState({});
  const handleModal = (cell) => {
    setItemSelected(cell.row);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const subscription = defer(() => getClientesList()).subscribe((list) => {
      setItemsList(list);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const localRefresh = async (updatedItem) => {
    setLoading(true);
    const idxToRefresh = itemsList.findIndex((c) => c.id === updatedItem.id);
    let updatedList = [];
    if (idxToRefresh > -1) {
      updatedList = [
        ...itemsList.slice(0, idxToRefresh),
        updatedItem,
        ...itemsList.slice(idxToRefresh + 1),
      ];
    } else {
      updatedList = [...itemsList, updatedItem];
    }
    setItemsList(updatedList);
    setLoading(false);
  };

  const fullRefresh = () => {
    setLoading(true);
    getClientesList().then((list) => {
      setItemsList(list);
      setLoading(false);
    });
  };

  const columns = [
    {
      field: "nombreCliente",
      headerName: "Cliente",
      width: 280,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.nombreCliente}>
            <span>{params.row.nombreCliente}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "nombreContacto",
      headerName: "Contacto",
      width: 280,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.nombreContacto}>
            <span>{params.row.nombreContacto}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "correo",
      headerName: "Correo",
      width: 220,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.correo}>
            <span>{params.row.correo}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "telefono",
      headerName: "Telefono",
      width: 160,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.telefono}>
            <span>{params.row.telefono}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "pais",
      headerName: "Pais",
      width: 120,
      align: "center",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.pais}>
            <span>{params.row.pais}</span>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Box sx={{ display: "flex", mt: 4, mb: 4 }}>
      <NavBar selected="Clientes" />
      <Container maxWidth="lg">
        <Header title="Clientes" subtitle="Listado de Clientes" />
        <HeaderActions
          addItem={() => {
            setItemSelected({});
            setIsModalOpen(true);
          }}
          addItemText="Agregar Cliente"
          refresh={fullRefresh}
        />
        <ClienteModal
          item={itemSelected}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          refresh={localRefresh}
        />
        <DataGrid
          rows={itemsList}
          columns={columns}
          sx={{ width: "100%", height: 600 }}
          loading={isLoading}
          autoHeight
          pageSize={15}
          rowsPerPageOptions={[15]}
          onCellClick={handleModal}
        />
      </Container>
    </Box>
  );
};

export default Clientes;
