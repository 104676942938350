import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const header = theme.header;

  return (
    <Box
      sx={{
        mb: "16px",
        padding: 2,
        borderRadius: "0.5rem",
        backgroundColor: header.backgroundColor,
        borderColor: header.borderColor,
        borderStyle: "solid",
        borderWidth: "1px",
      }}
    >
      <Typography
        variant="h3"
        color={header.textColor}
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={header.subtextColor}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
