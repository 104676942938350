import React, { useState, useEffect } from "react";
import { defer } from "rxjs";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import { CheckSquare, XSquare } from "phosphor-react";
import Header from "../../components/Header";
import { NavBar } from "../../components/NavBar";
import HeaderActions from "../../components/Header-actions";
import { getVersionesCursos } from "../../services/cursos.versiones.service";
import { countryToFlag } from "../../services/countries.service";
import VersionModal from "./VersionModal";

const VersionesCursos = () => {
  const [versionesCursos, setVersionesCursos] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [versionSelected, setVersionSelected] = useState({});
  const handleModal = (cell) => {
    setVersionSelected(cell.row);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const subscription = defer(() => getVersionesCursos()).subscribe((list) => {
      setVersionesCursos(list);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const localRefresh = async (updatedItem) => {
    setLoading(true);
    const idxToRefresh = versionesCursos.findIndex(
      (c) => c.id === updatedItem.id
    );
    let updatedList = [];
    if (idxToRefresh > -1) {
      updatedList = [
        ...versionesCursos.slice(0, idxToRefresh),
        updatedItem,
        ...versionesCursos.slice(idxToRefresh + 1),
      ];
    } else {
      updatedList = [...versionesCursos, updatedItem];
    }
    setVersionesCursos(updatedList);
    setLoading(false);
  };

  const fullRefresh = () => {
    setLoading(true);
    getVersionesCursos().then((list) => {
      setVersionesCursos(list);
      setLoading(false);
    });
  };

  const columns = [
    {
      field: "codigo",
      headerName: "Codigo Curso",
      width: 120,
      renderCell: (params) => {
        return <strong>{params.row.codigo}</strong>;
      },
    },
    {
      field: "version",
      headerName: "Version",
      width: 80,
      renderCell: (params) => {
        return <strong>{params.row.version}</strong>;
      },
    },
    {
      field: "desde",
      headerName: "Desde",
      width: 120,
    },
    {
      field: "horas",
      headerName: "Horas",
      width: 80,
    },
    {
      field: "contenidoEN",
      headerName: `Contenido ${countryToFlag("GB")}`,
      description: "Contenido en Ingles.",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.row.contenidos && params.row.contenidos["en"] ? (
          <Tooltip
            title={
              params.row.contenidosTexto &&
              params.row.contenidosTexto["en"] &&
              JSON.parse(params.row.contenidosTexto["en"])
            }
          >
            <CheckSquare size={24} color="#0e9524" />
          </Tooltip>
        ) : (
          <XSquare size={24} color="#ea1f1f" />
        );
      },
    },
    {
      field: "contenidoES",
      headerName: `Contenido ${countryToFlag("ES")}`,
      description: "Contenido en Español.",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.row.contenidos && params.row.contenidos["es"] ? (
          <Tooltip
            title={
              params.row.contenidosTexto &&
              params.row.contenidosTexto["es"] &&
              JSON.parse(params.row.contenidosTexto["es"])
            }
          >
            <CheckSquare size={24} color="#0e9524" />
          </Tooltip>
        ) : (
          <XSquare size={24} color="#ea1f1f" />
        );
      },
    },
    {
      field: "contenidoFR",
      headerName: `Contenido ${countryToFlag("FR")}`,
      description: "Contenido en Frances.",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.row.contenidos && params.row.contenidos["fr"] ? (
          <Tooltip
            title={
              params.row.contenidosTexto &&
              params.row.contenidosTexto["fr"] &&
              JSON.parse(params.row.contenidosTexto["fr"])
            }
          >
            <CheckSquare size={24} color="#0e9524" />
          </Tooltip>
        ) : (
          <XSquare size={24} color="#ea1f1f" />
        );
      },
    },
    {
      field: "contenidoHE",
      headerName: `Contenido ${countryToFlag("IL")}`,
      description: "Contenido en Hebreo.",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.row.contenidos && params.row.contenidos["he"] ? (
          <Tooltip
            title={
              params.row.contenidosTexto &&
              params.row.contenidosTexto["he"] &&
              JSON.parse(params.row.contenidosTexto["he"])
            }
          >
            <CheckSquare size={24} color="#0e9524" />
          </Tooltip>
        ) : (
          <XSquare size={24} color="#ea1f1f" />
        );
      },
    },
    {
      field: "contenidoAR",
      headerName: `Contenido ${countryToFlag("MA")}`,
      description: "Contenido en Arabe.",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.row.contenidos && params.row.contenidos["ar"] ? (
          <Tooltip
            title={
              params.row.contenidosTexto &&
              params.row.contenidosTexto["ar"] &&
              JSON.parse(params.row.contenidosTexto["ar"])
            }
          >
            <CheckSquare size={24} color="#0e9524" />
          </Tooltip>
        ) : (
          <XSquare size={24} color="#ea1f1f" />
        );
      },
    },
  ];

  return (
    <Box sx={{ display: "flex", mt: 4, mb: 4 }}>
      <NavBar selected="Versiones Cursos" />
      <Container maxWidth="lg">
        <Header
          title="Versiones de Cursos"
          subtitle="Listado de versiones de cursos"
        />
        <HeaderActions
          addItem={() => {
            setVersionSelected({});
            setIsModalOpen(true);
          }}
          addItemText="Agregar Version"
          refresh={fullRefresh}
        />
        <VersionModal
          item={versionSelected}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          refresh={localRefresh}
        />
        <DataGrid
          rows={versionesCursos}
          columns={columns}
          sx={{ width: "100%", height: 600 }}
          loading={isLoading}
          autoHeight
          pageSize={15}
          rowsPerPageOptions={[15]}
          onCellClick={handleModal}
        />
      </Container>
    </Box>
  );
};

export default VersionesCursos;
