import { firestore } from "../firebase";
import { collection, getDocs, query, orderBy, where } from "firebase/firestore";
import { startOfToday } from "date-fns";

const EDICIONES_COL = "registroCalendario";

export const getEdicionesActivas = async () => {
  const colRef = collection(firestore, EDICIONES_COL);
  const today = startOfToday();
  const q = query(
    colRef,
    where("fin_timestamp", ">=", today.getTime() / 1000),
    orderBy("fin_timestamp", "asc")
  );
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((c) => {
    const data = c.data();
    const estado = getEstadoEdicion(data.inicio_timestamp, data.fin_timestamp);
    const problemas = getProblemasEdicion(data);
    return { ...data, estado, problemas, id: c.id };
  });
};

const getEstadoEdicion = (inicio, fin) => {
  const today = startOfToday();
  if (inicio > today.getTime() / 1000) return "No empezado";
  else if (fin > today.getTime() / 1000) return "Activo";
  else return "Finalizado";
};

//TODO: hay que traer data de tipo edicion (winda /no winda), hay que traer los usuarios, ver si hay preregistrados...
const getProblemasEdicion = (edicion) => {
  let problemas = [];
  if (!edicion.modulos || edicion.modulos.length < 1) {
    problemas.push("La edicion no tiene modulos");
  }
  if (!edicion.correos_alumnos || edicion.correos_alumnos.length < 1) {
    problemas.push("La edicion no tiene alumnos inscriptos");
  }
  return problemas.length > 0 ? problemas : undefined;
};
