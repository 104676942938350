import React, { useState, useEffect } from "react";
import { defer } from "rxjs";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Header from "../../components/Header";
import { NavBar } from "../../components/NavBar";
import { getConfigurations } from "../../services/config.service";
import HeaderActions from "../../components/Header-actions";
import Tooltip from "@mui/material/Tooltip";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Configuracion = () => {
  const [itemsList, setItemsList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const subscription = defer(() => getConfigurations()).subscribe((list) => {
      setItemsList(list);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const localRefresh = async (updatedItem) => {
    setLoading(true);
    const idxToRefresh = itemsList.findIndex((c) => c.id === updatedItem.id);
    let updatedList = [];
    if (idxToRefresh > -1) {
      updatedList = [
        ...itemsList.slice(0, idxToRefresh),
        updatedItem,
        ...itemsList.slice(idxToRefresh + 1),
      ];
    } else {
      updatedList = [...itemsList, updatedItem];
    }
    setItemsList(updatedList);
    setLoading(false);
  };

  const fullRefresh = () => {
    setLoading(true);
    getConfigurations().then((list) => {
      setItemsList(list);
      setLoading(false);
    });
  };

  return (
    <Box sx={{ display: "flex", mt: 4, mb: 4 }}>
      <NavBar selected="Configuración general" />
      <Container maxWidth="lg">
        <Header title="Configuración" subtitle="Configuración general" />
        <HeaderActions refresh={fullRefresh} />

        {/* General */}
        <Accordion
          expanded={expanded === "general"}
          onChange={handleChange("general")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="generalbh-content"
            id="generalbh-header"
          >
            <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
              General
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Preferencias generales
            </Typography>
          </AccordionSummary>
          <AccordionDetails></AccordionDetails>
        </Accordion>

        {/* Tipo Cursos */}
        <Accordion
          expanded={expanded === "tipo-cursos"}
          onChange={handleChange("tipo-cursos")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="tipo-cursos-content"
            id="tipo-cursos-header"
          >
            <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
              Tipo de Cursos
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Tipos de cursos disponibles
            </Typography>
          </AccordionSummary>
          <AccordionDetails></AccordionDetails>
        </Accordion>

        {/* Winda API */}
        <Accordion
          expanded={expanded === "winda-api"}
          onChange={handleChange("winda-api")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="winda-api-content"
            id="winda-api-header"
          >
            <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
              API Winda
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Cuentas, cursos y tipos.
            </Typography>
          </AccordionSummary>
          <AccordionDetails></AccordionDetails>
        </Accordion>
      </Container>
    </Box>
  );
};

export default Configuracion;
