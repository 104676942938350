import { AuthProvider } from "./context/AuthContext";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Route, Routes, Navigate } from "react-router";
import { useState } from "react";
import { getCurrentLocale } from "./services/intl.service";
import { ThemeProvider } from "@mui/material/styles";
import { mainTheme } from "./assets/theme/material-ui-theme";
import routes from "./routes";
import CssBaseline from "@mui/material/CssBaseline";

function App() {
  const [currentLang, setCurrentLang] = useState(getCurrentLocale());

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        const element = route.protected ? (
          <ProtectedRoute>{route.component}</ProtectedRoute>
        ) : (
          route.component
        );
        return (
          <Route exact path={route.route} element={element} key={route.key} />
        );
      }

      return null;
    });

  return (
    <AuthProvider>
      <CssBaseline />
      <ThemeProvider theme={mainTheme}>
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
