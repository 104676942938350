import { firestore } from "../firebase";
import {
  collection,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  setDoc,
} from "firebase/firestore";

const CURSOS_CONFIG_COL = "cursos.config";

export const getCursosConfigList = async () => {
  const cursos = await getCursosConfig();
  return cursos.map((c) => {
    return { ...c.data(), id: c.id };
  });
};

const getCursosConfig = async () => {
  const colRef = await getDocs(collection(firestore, CURSOS_CONFIG_COL));
  return colRef.empty ? [] : colRef.docs;
};

export const getCursoConfig = async (id) => {
  const cursoDoc = await getDoc(doc(firestore, CURSOS_CONFIG_COL, id));
  return cursoDoc.exists() ? cursoDoc.data() : {};
};

export const getCodigoCursos = async () => {
  const cursos = await getCursosConfig();
  return cursos.map((c) => {
    return c.data().codigo;
  });
};

export const saveCurso = async (cursoData) => {
  const cursoDoc = doc(firestore, CURSOS_CONFIG_COL, cursoData.codigo);
  try {
    await updateDoc(cursoDoc, cursoData);
    return { success: true, data: cursoData };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false, error: err };
  }
};

export const createCurso = async (cursoData) => {
  const cursoDoc = doc(firestore, CURSOS_CONFIG_COL, cursoData.codigo);
  const actualDoc = await getDoc(cursoDoc);
  if (actualDoc.exists()) {
    return { success: false, error: "Ya existe un curso con ese Codigo" };
  }
  try {
    const data = { ...cursoData, id: cursoData.codigo };
    await setDoc(cursoDoc, data);
    return { success: true, data };
  } catch (err) {
    console.log("Error: ", err);
    return { success: false, error: err };
  }
};
