import React, { useState, useEffect } from "react";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { countryToFlag } from "../../services/countries.service";

export default function Contenidos({
  contenidos,
  setContenidos,
  isLoading,
  isOpen,
}) {
  const [idioma, setIdioma] = useState("en");
  const initialState = () => {
    return {
      en: '""',
      he: '""',
      fr: '""',
      ar: '""',
      es: '""',
    };
  };
  const [contenidosTexto, setContenidosTexto] = useState(initialState());

  const langOptions = [
    { value: "en", name: "Inglés", flag: "GB" },
    { value: "es", name: "Español", flag: "ES" },
    { value: "fr", name: "Francés", flag: "FR" },
    { value: "he", name: "Hebreo", flag: "IL" },
    { value: "ar", name: "Árabe", flag: "MA" },
  ];

  useEffect(() => {
    setContenidosTexto(contenidos);
  }, [isOpen, contenidos]);

  const handleSelectIdioma = (event, newAlignment) => {
    setIdioma(newAlignment);
  };

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setContenidosTexto({ ...contenidosTexto, [name]: JSON.stringify(value) });
  };

  const onInputBlur = (event) => {
    const { name, value } = event.target;
    setContenidos(name, value);
  };

  return (
    <Box
      component="span"
      sx={{
        p: 2,
        border: "1px solid #e1e2e5",
        borderRadius: "4px",
        flexGrow: 1,
        ml: "16px",
      }}
    >
      <Typography variant="subtitle1" sx={{ fontSize: "small" }}>
        La descripción de contenidos del curso debe introducirse como: un item
        por linea, y cada linea debe ser numerada (1. , 2. , 2.1 , 2.1.1 ,
        etc.).
      </Typography>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Tabs
            value={idioma}
            onChange={handleSelectIdioma}
            aria-label="Elegir idioma"
          >
            {langOptions.map((lang) => (
              <Tab
                key={lang.value}
                value={lang.value}
                disabled={isLoading}
                label={
                  <Tooltip title={lang.name}>
                    {!contenidosTexto[lang.value] ||
                    contenidosTexto[lang.value] === '""' ? (
                      <Badge color="error" variant="dot">
                        <Typography variant="h3">
                          {countryToFlag(lang.flag)}
                        </Typography>
                      </Badge>
                    ) : (
                      <Typography variant="h3">
                        {countryToFlag(lang.flag)}
                      </Typography>
                    )}
                  </Tooltip>
                }
              />
            ))}
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          {langOptions.map((lang) => (
            <div
              role="tabpanel"
              hidden={idioma !== lang.value}
              id={`tabpanel-${lang.value}`}
              key={`tabpanel-${lang.value}`}
              aria-labelledby={`tab-${lang.value}`}
            >
              {idioma === lang.value && (
                <TextField
                  id={`textarea-${lang.value}`}
                  name={lang.value}
                  label={`Contenidos en ${lang.name}`}
                  placeholder={`Contenidos en ${lang.name}`}
                  value={JSON.parse(contenidosTexto[lang.value] || '""')}
                  onChange={onInputChange}
                  onBlur={onInputBlur}
                  fullWidth
                  rows={14}
                  multiline
                  disabled={isLoading}
                />
              )}
            </div>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
