import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Loader } from "../../components/controls/Loader";

import { createCliente, saveCliente } from "../../services/clientes.service";

const ClienteModal = ({ item, isOpen, setIsOpen, refresh }) => {
  const initialFormState = () => {
    return {
      nombreCliente: "",
      nombreContacto: "",
      correo: "",
      telefono: "",
      pais: "",
    };
  };
  const [isCreation, setIsCreation] = useState(true);
  const [formData, setFormData] = useState(initialFormState());
  const [isLoading, setLoading] = useState(true);
  const [inputError, setInputError] = useState({});

  useEffect(() => {
    setLoading(true);
    if (item && Object.keys(item).length > 0) {
      setFormData({ ...initialFormState(), ...item });
      setIsCreation(false);
    } else {
      setFormData(initialFormState());
      setIsCreation(true);
    }
    setLoading(false);
  }, [isOpen, item]);

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleClose = () => {
    setIsOpen(false);
    setInputError({});
  };
  const handleSave = async () => {
    setLoading(true);
    if (validateInput()) {
      const res = isCreation
        ? await createCliente(formData)
        : await saveCliente(formData);
      if (res.success) {
        refresh(res.data);
        handleClose();
      } else {
        setInputError({ error: `Ocurrio un error: ${res.error}` });
      }
    }
    setLoading(false);
  };
  const validateInput = () => {
    const tempError = {};
    tempError.nombreCliente = formData.nombreCliente
      ? ""
      : "El cliente es requerido";
    tempError.nombreContacto = formData.nombreContacto
      ? ""
      : "El nombre de contacto es requerido";
    tempError.correo = formData.correo ? "" : "El correo es requerido";

    setInputError(tempError);
    return Object.values(tempError).every((e) => !e);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {formData.correo
          ? `Cliente: ${formData.nombreCliente}`
          : "Nuevo Cliente"}
      </DialogTitle>
      <DialogContent sx={{ pt: "16px !important" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              id="cliente"
              name="nombreCliente"
              label="Cliente"
              fullWidth
              value={formData.nombreCliente}
              onChange={onInputChange}
              disabled={isLoading}
              error={!!inputError["nombreCliente"]}
              helperText={inputError["nombreCliente"]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="contacto"
              name="nombreContacto"
              label="Contacto"
              fullWidth
              value={formData.nombreContacto}
              onChange={onInputChange}
              disabled={isLoading}
              error={!!inputError["nombreContacto"]}
              helperText={inputError["nombreContacto"]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="correo"
              name="correo"
              label="Correo"
              fullWidth
              value={formData.correo}
              onChange={onInputChange}
              disabled={isLoading}
              error={!!inputError["correo"]}
              helperText={inputError["correo"]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="telefono"
              name="telefono"
              label="Telefono"
              fullWidth
              value={formData.telefono}
              onChange={onInputChange}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="pais"
              name="pais"
              label="Pais"
              fullWidth
              value={formData.pais}
              onChange={onInputChange}
              disabled={isLoading}
            />
          </Grid>

          {!!inputError.error ? (
            <Grid item xs={12}>
              <span style={{ color: "#ff0000" }}>{inputError.error}</span>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {isLoading && <Loader />}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cerrar
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={isLoading}>
          {isCreation ? "Crear Cliente" : "Guardar cambios"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClienteModal;
