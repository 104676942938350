import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";

import { Loader } from "../../components/controls/Loader";
import { countryToFlag } from "../../services/countries.service";
import {
  getCodigoCursos,
  saveCurso,
  createCurso,
} from "../../services/cursos.service";
import { getTipoCursos } from "../../services/config.service";
import { Typography } from "@mui/material";

const CursoModal = ({ curso, isOpen, setIsOpen, refresh }) => {
  const initialFormState = () => {
    return {
      codigo: "",
      codigo_base: "",
      tipo_curso: "",
      titulo: "",
      horas: 0,
      refresher: false,
      requerimientos: [],
      lang: {
        he: "",
        fr: "",
        ar: "",
        es: "",
      },
      validez_meses: 0,
    };
  };
  const [isCreation, setIsCreation] = useState(true);
  const [formData, setFormData] = useState(initialFormState());
  const [isLoading, setLoading] = useState(true);
  const [inputError, setInputError] = useState({});
  const [tipoCursos, setTipoCursos] = useState([]);
  const [codigosCurso, setCodigosCurso] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (curso && Object.keys(curso).length > 0) {
      setFormData(curso);
      setIsCreation(false);
    } else {
      setFormData(initialFormState());
      setIsCreation(true);
    }
    setLoading(false);
  }, [isOpen, curso]);

  useEffect(() => {
    getTipoCursos().then((data) => {
      setTipoCursos(data || []);
    });
  }, []);

  useEffect(() => {
    getCodigoCursos().then((data) => {
      setCodigosCurso(data || []);
    });
  }, []);

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleClose = () => {
    setIsOpen(false);
    setInputError({});
  };
  const handleSave = async () => {
    setLoading(true);
    if (validateInput()) {
      const res = isCreation
        ? await createCurso(formData)
        : await saveCurso(formData);
      if (res.success) {
        refresh(res.data);
        handleClose();
      } else {
        setInputError({ error: `Ocurrio un error: ${res.error}` });
      }
    }
    setLoading(false);
  };
  const validateInput = () => {
    const tempError = {};
    tempError.codigo = formData.codigo ? "" : "El codigo es requerido";
    tempError.codigo_base = formData.codigo_base
      ? ""
      : "El codigo base es requerido";
    tempError.titulo = formData.titulo ? "" : "El titulo es requerido";
    tempError.tipo_curso = formData.tipo_curso
      ? ""
      : "El tipo de curso es requerido";

    setInputError(tempError);
    return Object.values(tempError).every((e) => !e);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {formData.codigo ? `Curso: ${formData.codigo}` : "Nuevo Curso"}
      </DialogTitle>
      <DialogContent sx={{ pt: "16px !important" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="codigo"
              name="codigo"
              label="Codigo"
              fullWidth
              value={formData.codigo}
              onChange={onInputChange}
              disabled={isLoading || !!formData.id}
              error={!!inputError["codigo"]}
              helperText={inputError["codigo"]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="codigoBase"
              name="codigo_base"
              label="Codigo Base"
              fullWidth
              value={formData.codigo_base}
              onChange={onInputChange}
              disabled={isLoading || !!formData.id}
              error={!!inputError["codigo_base"]}
              helperText={inputError["codigo_base"]}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="titulo"
              label="Titulo"
              name="titulo"
              fullWidth
              value={formData.titulo}
              onChange={onInputChange}
              disabled={isLoading}
              error={!!inputError["titulo"]}
              helperText={inputError["titulo"]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="tipoCurso-label">Tipo</InputLabel>
              <Select
                labelId="tipoCurso-label"
                id="tipo-select"
                label="Tipo"
                name="tipo_curso"
                value={formData.tipo_curso}
                onChange={onInputChange}
              >
                {tipoCursos.map((t) => (
                  <MenuItem key={t} value={t}>
                    {t}
                  </MenuItem>
                ))}
              </Select>
              {!!inputError.tipo_curso ? (
                <FormHelperText style={{ color: "#ff0000" }}>
                  {inputError.tipo_curso}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="refresher-label">Refresco?</InputLabel>
              <Select
                labelId="refresher-label"
                id="refresher-select"
                label="Refresco"
                name="refresher"
                value={formData.refresher}
                onChange={onInputChange}
              >
                <MenuItem value={false}>NO</MenuItem>
                <MenuItem value={true}>SI</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="validez"
              label="Validez (meses)"
              name="validez_meses"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={formData.validez_meses}
              onChange={onInputChange}
              disabled={isLoading}
              error={inputError["validez_meses"]}
              helperText={inputError["validez_meses"]}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              id="requerimientos-auto-chip"
              name="requerimientos"
              multiple
              value={formData.requerimientos}
              onChange={(event, newValue) => {
                setFormData({ ...formData, requerimientos: newValue });
              }}
              options={codigosCurso}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label="Requerimientos" />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ fontSize: "small" }}>
              Otros idiomas:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="tituloES"
              label={`Titulo ${countryToFlag("ES")}`}
              size="small"
              fullWidth
              value={formData.lang["es"]}
              onChange={(event) => {
                const lang = formData.lang;
                setFormData({
                  ...formData,
                  lang: { ...lang, es: event.target.value },
                });
              }}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="tituloFR"
              label={`Titulo ${countryToFlag("FR")}`}
              size="small"
              fullWidth
              value={formData.lang["fr"] || ""}
              onChange={(event) => {
                const lang = formData.lang;
                setFormData({
                  ...formData,
                  lang: { ...lang, fr: event.target.value },
                });
              }}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="tituloHE"
              label={`Titulo ${countryToFlag("IL")}`}
              size="small"
              fullWidth
              value={formData.lang["he"]}
              onChange={(event) => {
                const lang = formData.lang;
                setFormData({
                  ...formData,
                  lang: { ...lang, he: event.target.value },
                });
              }}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="tituloAR"
              label={`Titulo ${countryToFlag("MA")}`}
              size="small"
              fullWidth
              value={formData.lang.ar || ""}
              onChange={(event) => {
                const lang = formData.lang;
                setFormData({
                  ...formData,
                  lang: { ...lang, ar: event.target.value },
                });
              }}
              disabled={isLoading}
            />
          </Grid>

          {!!inputError.error ? (
            <Grid item xs={12}>
              <span style={{ color: "#ff0000" }}>{inputError.error}</span>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {isLoading && <Loader />}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cerrar
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={isLoading}>
          {isCreation ? "Crear curso" : "Guardar cambios"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CursoModal;
